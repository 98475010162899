// source: web.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_api_annotations_pb = require('./google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var error_pb = require('./error_pb.js');
goog.object.extend(proto, error_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.chainhop.AnalyticsDataRequest', null, global);
goog.exportSymbol('proto.chainhop.AnalyticsDataResponse', null, global);
goog.exportSymbol('proto.chainhop.FixSwapRequest', null, global);
goog.exportSymbol('proto.chainhop.FixSwapResponse', null, global);
goog.exportSymbol('proto.chainhop.GetConfigsRequest', null, global);
goog.exportSymbol('proto.chainhop.GetConfigsResponse', null, global);
goog.exportSymbol('proto.chainhop.GetHistoryByTxRequest', null, global);
goog.exportSymbol('proto.chainhop.GetHistoryByTxResponse', null, global);
goog.exportSymbol('proto.chainhop.GetHistoryRequest', null, global);
goog.exportSymbol('proto.chainhop.GetHistoryResponse', null, global);
goog.exportSymbol('proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest', null, global);
goog.exportSymbol('proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse', null, global);
goog.exportSymbol('proto.chainhop.GetStatusRequest', null, global);
goog.exportSymbol('proto.chainhop.GetStatusResponse', null, global);
goog.exportSymbol('proto.chainhop.GetTokenListRequest', null, global);
goog.exportSymbol('proto.chainhop.GetTokenListResponse', null, global);
goog.exportSymbol('proto.chainhop.InternalGetHistoryRequest', null, global);
goog.exportSymbol('proto.chainhop.InternalGetHistoryResponse', null, global);
goog.exportSymbol('proto.chainhop.InternalHistory', null, global);
goog.exportSymbol('proto.chainhop.MarkRefRelationRequest', null, global);
goog.exportSymbol('proto.chainhop.MarkRefRelationResponse', null, global);
goog.exportSymbol('proto.chainhop.QuoteRequest', null, global);
goog.exportSymbol('proto.chainhop.QuoteResponse', null, global);
goog.exportSymbol('proto.chainhop.RfqMarkAsSubmittingRequest', null, global);
goog.exportSymbol('proto.chainhop.RfqMarkAsSubmittingResponse', null, global);
goog.exportSymbol('proto.chainhop.RfqQuote', null, global);
goog.exportSymbol('proto.chainhop.RfqQuoteRequest', null, global);
goog.exportSymbol('proto.chainhop.RfqQuoteResponse', null, global);
goog.exportSymbol('proto.chainhop.RfqRefundRequest', null, global);
goog.exportSymbol('proto.chainhop.RfqRefundResponse', null, global);
goog.exportSymbol('proto.chainhop.SwapRequest', null, global);
goog.exportSymbol('proto.chainhop.SwapResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetTokenListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.GetTokenListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetTokenListRequest.displayName = 'proto.chainhop.GetTokenListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetTokenListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.GetTokenListResponse.repeatedFields_, null);
};
goog.inherits(proto.chainhop.GetTokenListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetTokenListResponse.displayName = 'proto.chainhop.GetTokenListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.InternalGetHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.InternalGetHistoryRequest.repeatedFields_, null);
};
goog.inherits(proto.chainhop.InternalGetHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.InternalGetHistoryRequest.displayName = 'proto.chainhop.InternalGetHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.InternalGetHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.InternalGetHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.chainhop.InternalGetHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.InternalGetHistoryResponse.displayName = 'proto.chainhop.InternalGetHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.InternalHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.InternalHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.InternalHistory.displayName = 'proto.chainhop.InternalHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.FixSwapRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.FixSwapRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.FixSwapRequest.displayName = 'proto.chainhop.FixSwapRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.FixSwapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.FixSwapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.FixSwapResponse.displayName = 'proto.chainhop.FixSwapResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.MarkRefRelationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.MarkRefRelationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.MarkRefRelationRequest.displayName = 'proto.chainhop.MarkRefRelationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.MarkRefRelationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.MarkRefRelationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.MarkRefRelationResponse.displayName = 'proto.chainhop.MarkRefRelationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.AnalyticsDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.AnalyticsDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.AnalyticsDataRequest.displayName = 'proto.chainhop.AnalyticsDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.AnalyticsDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.AnalyticsDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.AnalyticsDataResponse.displayName = 'proto.chainhop.AnalyticsDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetConfigsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.GetConfigsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetConfigsRequest.displayName = 'proto.chainhop.GetConfigsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetConfigsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.GetConfigsResponse.repeatedFields_, null);
};
goog.inherits(proto.chainhop.GetConfigsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetConfigsResponse.displayName = 'proto.chainhop.GetConfigsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.QuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.QuoteRequest.repeatedFields_, null);
};
goog.inherits(proto.chainhop.QuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.QuoteRequest.displayName = 'proto.chainhop.QuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.QuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.QuoteResponse.repeatedFields_, null);
};
goog.inherits(proto.chainhop.QuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.QuoteResponse.displayName = 'proto.chainhop.QuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.SwapRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.SwapRequest.repeatedFields_, null);
};
goog.inherits(proto.chainhop.SwapRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.SwapRequest.displayName = 'proto.chainhop.SwapRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.SwapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.SwapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.SwapResponse.displayName = 'proto.chainhop.SwapResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetHistoryByTxRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.GetHistoryByTxRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetHistoryByTxRequest.displayName = 'proto.chainhop.GetHistoryByTxRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetHistoryByTxResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.GetHistoryByTxResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetHistoryByTxResponse.displayName = 'proto.chainhop.GetHistoryByTxResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.GetHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetHistoryRequest.displayName = 'proto.chainhop.GetHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.GetHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.chainhop.GetHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetHistoryResponse.displayName = 'proto.chainhop.GetHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.GetStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetStatusRequest.displayName = 'proto.chainhop.GetStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.GetStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetStatusResponse.displayName = 'proto.chainhop.GetStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.displayName = 'proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.displayName = 'proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.RfqQuoteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.RfqQuoteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.RfqQuoteRequest.displayName = 'proto.chainhop.RfqQuoteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.RfqQuoteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.RfqQuoteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.RfqQuoteResponse.displayName = 'proto.chainhop.RfqQuoteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.RfqQuote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.RfqQuote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.RfqQuote.displayName = 'proto.chainhop.RfqQuote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.RfqRefundRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.RfqRefundRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.RfqRefundRequest.displayName = 'proto.chainhop.RfqRefundRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.RfqRefundResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.RfqRefundResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.RfqRefundResponse.displayName = 'proto.chainhop.RfqRefundResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.RfqMarkAsSubmittingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.RfqMarkAsSubmittingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.RfqMarkAsSubmittingRequest.displayName = 'proto.chainhop.RfqMarkAsSubmittingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.RfqMarkAsSubmittingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.RfqMarkAsSubmittingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.RfqMarkAsSubmittingResponse.displayName = 'proto.chainhop.RfqMarkAsSubmittingResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetTokenListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetTokenListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetTokenListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetTokenListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetTokenListRequest}
 */
proto.chainhop.GetTokenListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetTokenListRequest;
  return proto.chainhop.GetTokenListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetTokenListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetTokenListRequest}
 */
proto.chainhop.GetTokenListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetTokenListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetTokenListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetTokenListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetTokenListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.GetTokenListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetTokenListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetTokenListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetTokenListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetTokenListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokensList: jspb.Message.toObjectList(msg.getTokensList(),
    common_pb.Token.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetTokenListResponse}
 */
proto.chainhop.GetTokenListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetTokenListResponse;
  return proto.chainhop.GetTokenListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetTokenListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetTokenListResponse}
 */
proto.chainhop.GetTokenListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Token;
      reader.readMessage(value,common_pb.Token.deserializeBinaryFromReader);
      msg.addTokens(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetTokenListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetTokenListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetTokenListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetTokenListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokensList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.Token.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Token tokens = 1;
 * @return {!Array<!proto.chainhop.Token>}
 */
proto.chainhop.GetTokenListResponse.prototype.getTokensList = function() {
  return /** @type{!Array<!proto.chainhop.Token>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Token, 1));
};


/**
 * @param {!Array<!proto.chainhop.Token>} value
 * @return {!proto.chainhop.GetTokenListResponse} returns this
*/
proto.chainhop.GetTokenListResponse.prototype.setTokensList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.chainhop.Token=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chainhop.Token}
 */
proto.chainhop.GetTokenListResponse.prototype.addTokens = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.chainhop.Token, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.GetTokenListResponse} returns this
 */
proto.chainhop.GetTokenListResponse.prototype.clearTokensList = function() {
  return this.setTokensList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.InternalGetHistoryRequest.repeatedFields_ = [3,4,5,8,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.InternalGetHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.InternalGetHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.InternalGetHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    addressesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    srcChainIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    dstChainIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    fromTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    toTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    statusList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    bridgeTypesList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    swapTypesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.InternalGetHistoryRequest}
 */
proto.chainhop.InternalGetHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.InternalGetHistoryRequest;
  return proto.chainhop.InternalGetHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.InternalGetHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.InternalGetHistoryRequest}
 */
proto.chainhop.InternalGetHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAddresses(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSrcChainIds(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDstChainIds(values[i]);
      }
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToTime(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addBridgeTypes(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.chainhop.SwapTypeCS>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSwapTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.InternalGetHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.InternalGetHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.InternalGetHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSrcChainIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      4,
      f
    );
  }
  f = message.getDstChainIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getFromTime();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getToTime();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedUint32(
      8,
      f
    );
  }
  f = message.getBridgeTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getSwapTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
};


/**
 * optional string next_page_token = 1;
 * @return {string}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 page_size = 2;
 * @return {number}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string addresses = 3;
 * @return {!Array<string>}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getAddressesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setAddressesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.addAddresses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};


/**
 * repeated uint64 src_chain_ids = 4;
 * @return {!Array<number>}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getSrcChainIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setSrcChainIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.addSrcChainIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.clearSrcChainIdsList = function() {
  return this.setSrcChainIdsList([]);
};


/**
 * repeated uint64 dst_chain_ids = 5;
 * @return {!Array<number>}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getDstChainIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setDstChainIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.addDstChainIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.clearDstChainIdsList = function() {
  return this.setDstChainIdsList([]);
};


/**
 * optional uint64 from_time = 6;
 * @return {number}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getFromTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setFromTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 to_time = 7;
 * @return {number}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getToTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setToTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated uint32 status = 8;
 * @return {!Array<number>}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getStatusList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * repeated string bridge_types = 9;
 * @return {!Array<string>}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getBridgeTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setBridgeTypesList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.addBridgeTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.clearBridgeTypesList = function() {
  return this.setBridgeTypesList([]);
};


/**
 * repeated SwapTypeCS swap_types = 10;
 * @return {!Array<!proto.chainhop.SwapTypeCS>}
 */
proto.chainhop.InternalGetHistoryRequest.prototype.getSwapTypesList = function() {
  return /** @type {!Array<!proto.chainhop.SwapTypeCS>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.chainhop.SwapTypeCS>} value
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.setSwapTypesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.chainhop.SwapTypeCS} value
 * @param {number=} opt_index
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.addSwapTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.InternalGetHistoryRequest} returns this
 */
proto.chainhop.InternalGetHistoryRequest.prototype.clearSwapTypesList = function() {
  return this.setSwapTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.InternalGetHistoryResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.InternalGetHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.InternalGetHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.InternalGetHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.InternalGetHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.chainhop.InternalHistory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.InternalGetHistoryResponse}
 */
proto.chainhop.InternalGetHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.InternalGetHistoryResponse;
  return proto.chainhop.InternalGetHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.InternalGetHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.InternalGetHistoryResponse}
 */
proto.chainhop.InternalGetHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.chainhop.InternalHistory;
      reader.readMessage(value,proto.chainhop.InternalHistory.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.InternalGetHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.InternalGetHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.InternalGetHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.InternalGetHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.chainhop.InternalHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string next_page_token = 1;
 * @return {string}
 */
proto.chainhop.InternalGetHistoryResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.InternalGetHistoryResponse} returns this
 */
proto.chainhop.InternalGetHistoryResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 total = 2;
 * @return {number}
 */
proto.chainhop.InternalGetHistoryResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.InternalGetHistoryResponse} returns this
 */
proto.chainhop.InternalGetHistoryResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated InternalHistory entries = 3;
 * @return {!Array<!proto.chainhop.InternalHistory>}
 */
proto.chainhop.InternalGetHistoryResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.chainhop.InternalHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chainhop.InternalHistory, 3));
};


/**
 * @param {!Array<!proto.chainhop.InternalHistory>} value
 * @return {!proto.chainhop.InternalGetHistoryResponse} returns this
*/
proto.chainhop.InternalGetHistoryResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.chainhop.InternalHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chainhop.InternalHistory}
 */
proto.chainhop.InternalGetHistoryResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.chainhop.InternalHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.InternalGetHistoryResponse} returns this
 */
proto.chainhop.InternalGetHistoryResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.InternalHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.InternalHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.InternalHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.InternalHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    history: (f = msg.getHistory()) && common_pb.HopHistory.toObject(includeInstance, f),
    userAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cbridgeStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    executorFee: jspb.Message.getFieldWithDefault(msg, 4, 0),
    executorFeeToken: (f = msg.getExecutorFeeToken()) && common_pb.Token.toObject(includeInstance, f),
    srcTxExplorerLink: jspb.Message.getFieldWithDefault(msg, 6, ""),
    refId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    srcTokenUsdPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    dstTokenUsdPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    usdVolume: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.InternalHistory}
 */
proto.chainhop.InternalHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.InternalHistory;
  return proto.chainhop.InternalHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.InternalHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.InternalHistory}
 */
proto.chainhop.InternalHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.HopHistory;
      reader.readMessage(value,common_pb.HopHistory.deserializeBinaryFromReader);
      msg.setHistory(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCbridgeStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExecutorFee(value);
      break;
    case 5:
      var value = new common_pb.Token;
      reader.readMessage(value,common_pb.Token.deserializeBinaryFromReader);
      msg.setExecutorFeeToken(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcTxExplorerLink(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSrcTokenUsdPrice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDstTokenUsdPrice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUsdVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.InternalHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.InternalHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.InternalHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.InternalHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHistory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.HopHistory.serializeBinaryToWriter
    );
  }
  f = message.getUserAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCbridgeStatus();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getExecutorFee();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getExecutorFeeToken();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.Token.serializeBinaryToWriter
    );
  }
  f = message.getSrcTxExplorerLink();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRefId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSrcTokenUsdPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getDstTokenUsdPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getUsdVolume();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
};


/**
 * optional HopHistory history = 1;
 * @return {?proto.chainhop.HopHistory}
 */
proto.chainhop.InternalHistory.prototype.getHistory = function() {
  return /** @type{?proto.chainhop.HopHistory} */ (
    jspb.Message.getWrapperField(this, common_pb.HopHistory, 1));
};


/**
 * @param {?proto.chainhop.HopHistory|undefined} value
 * @return {!proto.chainhop.InternalHistory} returns this
*/
proto.chainhop.InternalHistory.prototype.setHistory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.clearHistory = function() {
  return this.setHistory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.InternalHistory.prototype.hasHistory = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string user_address = 2;
 * @return {string}
 */
proto.chainhop.InternalHistory.prototype.getUserAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.setUserAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 cbridge_status = 3;
 * @return {number}
 */
proto.chainhop.InternalHistory.prototype.getCbridgeStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.setCbridgeStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 executor_fee = 4;
 * @return {number}
 */
proto.chainhop.InternalHistory.prototype.getExecutorFee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.setExecutorFee = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Token executor_fee_token = 5;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.InternalHistory.prototype.getExecutorFeeToken = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, common_pb.Token, 5));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.InternalHistory} returns this
*/
proto.chainhop.InternalHistory.prototype.setExecutorFeeToken = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.clearExecutorFeeToken = function() {
  return this.setExecutorFeeToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.InternalHistory.prototype.hasExecutorFeeToken = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string src_tx_explorer_link = 6;
 * @return {string}
 */
proto.chainhop.InternalHistory.prototype.getSrcTxExplorerLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.setSrcTxExplorerLink = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ref_id = 7;
 * @return {string}
 */
proto.chainhop.InternalHistory.prototype.getRefId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.setRefId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float src_token_usd_price = 8;
 * @return {number}
 */
proto.chainhop.InternalHistory.prototype.getSrcTokenUsdPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.setSrcTokenUsdPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float dst_token_usd_price = 9;
 * @return {number}
 */
proto.chainhop.InternalHistory.prototype.getDstTokenUsdPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.setDstTokenUsdPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float usd_volume = 10;
 * @return {number}
 */
proto.chainhop.InternalHistory.prototype.getUsdVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.InternalHistory} returns this
 */
proto.chainhop.InternalHistory.prototype.setUsdVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.FixSwapRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.FixSwapRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.FixSwapRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.FixSwapRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcTx: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.FixSwapRequest}
 */
proto.chainhop.FixSwapRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.FixSwapRequest;
  return proto.chainhop.FixSwapRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.FixSwapRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.FixSwapRequest}
 */
proto.chainhop.FixSwapRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcTx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.FixSwapRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.FixSwapRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.FixSwapRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.FixSwapRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcTx();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string src_tx = 1;
 * @return {string}
 */
proto.chainhop.FixSwapRequest.prototype.getSrcTx = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.FixSwapRequest} returns this
 */
proto.chainhop.FixSwapRequest.prototype.setSrcTx = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.FixSwapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.FixSwapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.FixSwapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.FixSwapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.FixSwapResponse}
 */
proto.chainhop.FixSwapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.FixSwapResponse;
  return proto.chainhop.FixSwapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.FixSwapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.FixSwapResponse}
 */
proto.chainhop.FixSwapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.FixSwapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.FixSwapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.FixSwapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.FixSwapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.MarkRefRelationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.MarkRefRelationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.MarkRefRelationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.MarkRefRelationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrAddr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    swapId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.MarkRefRelationRequest}
 */
proto.chainhop.MarkRefRelationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.MarkRefRelationRequest;
  return proto.chainhop.MarkRefRelationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.MarkRefRelationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.MarkRefRelationRequest}
 */
proto.chainhop.MarkRefRelationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsrAddr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.MarkRefRelationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.MarkRefRelationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.MarkRefRelationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.MarkRefRelationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrAddr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSwapId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRefId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string usr_addr = 1;
 * @return {string}
 */
proto.chainhop.MarkRefRelationRequest.prototype.getUsrAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.MarkRefRelationRequest} returns this
 */
proto.chainhop.MarkRefRelationRequest.prototype.setUsrAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string swap_id = 2;
 * @return {string}
 */
proto.chainhop.MarkRefRelationRequest.prototype.getSwapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.MarkRefRelationRequest} returns this
 */
proto.chainhop.MarkRefRelationRequest.prototype.setSwapId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ref_id = 3;
 * @return {string}
 */
proto.chainhop.MarkRefRelationRequest.prototype.getRefId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.MarkRefRelationRequest} returns this
 */
proto.chainhop.MarkRefRelationRequest.prototype.setRefId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.MarkRefRelationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.MarkRefRelationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.MarkRefRelationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.MarkRefRelationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.MarkRefRelationResponse}
 */
proto.chainhop.MarkRefRelationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.MarkRefRelationResponse;
  return proto.chainhop.MarkRefRelationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.MarkRefRelationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.MarkRefRelationResponse}
 */
proto.chainhop.MarkRefRelationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.MarkRefRelationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.MarkRefRelationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.MarkRefRelationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.MarkRefRelationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.MarkRefRelationResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.MarkRefRelationResponse} returns this
*/
proto.chainhop.MarkRefRelationResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.MarkRefRelationResponse} returns this
 */
proto.chainhop.MarkRefRelationResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.MarkRefRelationResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.AnalyticsDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.AnalyticsDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.AnalyticsDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.AnalyticsDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.AnalyticsDataRequest}
 */
proto.chainhop.AnalyticsDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.AnalyticsDataRequest;
  return proto.chainhop.AnalyticsDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.AnalyticsDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.AnalyticsDataRequest}
 */
proto.chainhop.AnalyticsDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.AnalyticsDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.AnalyticsDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.AnalyticsDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.AnalyticsDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.AnalyticsDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.AnalyticsDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.AnalyticsDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.AnalyticsDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f),
    volumeTotal: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    volume24h: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    cntSwapTotal: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cntSwap24h: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cntUniqueAddr: jspb.Message.getFieldWithDefault(msg, 6, 0),
    cntToken: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.AnalyticsDataResponse}
 */
proto.chainhop.AnalyticsDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.AnalyticsDataResponse;
  return proto.chainhop.AnalyticsDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.AnalyticsDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.AnalyticsDataResponse}
 */
proto.chainhop.AnalyticsDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVolumeTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVolume24h(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCntSwapTotal(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCntSwap24h(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCntUniqueAddr(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCntToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.AnalyticsDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.AnalyticsDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.AnalyticsDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.AnalyticsDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getVolumeTotal();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getVolume24h();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getCntSwapTotal();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getCntSwap24h();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getCntUniqueAddr();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getCntToken();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.AnalyticsDataResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.AnalyticsDataResponse} returns this
*/
proto.chainhop.AnalyticsDataResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.AnalyticsDataResponse} returns this
 */
proto.chainhop.AnalyticsDataResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.AnalyticsDataResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float volume_total = 2;
 * @return {number}
 */
proto.chainhop.AnalyticsDataResponse.prototype.getVolumeTotal = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.AnalyticsDataResponse} returns this
 */
proto.chainhop.AnalyticsDataResponse.prototype.setVolumeTotal = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float volume_24h = 3;
 * @return {number}
 */
proto.chainhop.AnalyticsDataResponse.prototype.getVolume24h = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.AnalyticsDataResponse} returns this
 */
proto.chainhop.AnalyticsDataResponse.prototype.setVolume24h = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint64 cnt_swap_total = 4;
 * @return {number}
 */
proto.chainhop.AnalyticsDataResponse.prototype.getCntSwapTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.AnalyticsDataResponse} returns this
 */
proto.chainhop.AnalyticsDataResponse.prototype.setCntSwapTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 cnt_swap_24h = 5;
 * @return {number}
 */
proto.chainhop.AnalyticsDataResponse.prototype.getCntSwap24h = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.AnalyticsDataResponse} returns this
 */
proto.chainhop.AnalyticsDataResponse.prototype.setCntSwap24h = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 cnt_unique_addr = 6;
 * @return {number}
 */
proto.chainhop.AnalyticsDataResponse.prototype.getCntUniqueAddr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.AnalyticsDataResponse} returns this
 */
proto.chainhop.AnalyticsDataResponse.prototype.setCntUniqueAddr = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 cnt_token = 7;
 * @return {number}
 */
proto.chainhop.AnalyticsDataResponse.prototype.getCntToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.AnalyticsDataResponse} returns this
 */
proto.chainhop.AnalyticsDataResponse.prototype.setCntToken = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetConfigsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetConfigsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetConfigsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetConfigsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetConfigsRequest}
 */
proto.chainhop.GetConfigsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetConfigsRequest;
  return proto.chainhop.GetConfigsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetConfigsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetConfigsRequest}
 */
proto.chainhop.GetConfigsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetConfigsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetConfigsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetConfigsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetConfigsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.GetConfigsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetConfigsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetConfigsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetConfigsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetConfigsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f),
    chainsList: jspb.Message.toObjectList(msg.getChainsList(),
    common_pb.Chain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetConfigsResponse}
 */
proto.chainhop.GetConfigsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetConfigsResponse;
  return proto.chainhop.GetConfigsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetConfigsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetConfigsResponse}
 */
proto.chainhop.GetConfigsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = new common_pb.Chain;
      reader.readMessage(value,common_pb.Chain.deserializeBinaryFromReader);
      msg.addChains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetConfigsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetConfigsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetConfigsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetConfigsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getChainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_pb.Chain.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.GetConfigsResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.GetConfigsResponse} returns this
*/
proto.chainhop.GetConfigsResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.GetConfigsResponse} returns this
 */
proto.chainhop.GetConfigsResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.GetConfigsResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Chain chains = 2;
 * @return {!Array<!proto.chainhop.Chain>}
 */
proto.chainhop.GetConfigsResponse.prototype.getChainsList = function() {
  return /** @type{!Array<!proto.chainhop.Chain>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Chain, 2));
};


/**
 * @param {!Array<!proto.chainhop.Chain>} value
 * @return {!proto.chainhop.GetConfigsResponse} returns this
*/
proto.chainhop.GetConfigsResponse.prototype.setChainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.chainhop.Chain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chainhop.Chain}
 */
proto.chainhop.GetConfigsResponse.prototype.addChains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.chainhop.Chain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.GetConfigsResponse} returns this
 */
proto.chainhop.GetConfigsResponse.prototype.clearChainsList = function() {
  return this.setChainsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.QuoteRequest.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.QuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.QuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.QuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.QuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcToken: (f = msg.getSrcToken()) && common_pb.Token.toObject(includeInstance, f),
    dstToken: (f = msg.getDstToken()) && common_pb.Token.toObject(includeInstance, f),
    amountIn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    slippageTolerance: jspb.Message.getFieldWithDefault(msg, 4, 0),
    nativeOut: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    onlySwapsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    onlyBridgesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    nonce: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.QuoteRequest}
 */
proto.chainhop.QuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.QuoteRequest;
  return proto.chainhop.QuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.QuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.QuoteRequest}
 */
proto.chainhop.QuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Token;
      reader.readMessage(value,common_pb.Token.deserializeBinaryFromReader);
      msg.setSrcToken(value);
      break;
    case 2:
      var value = new common_pb.Token;
      reader.readMessage(value,common_pb.Token.deserializeBinaryFromReader);
      msg.setDstToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountIn(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSlippageTolerance(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNativeOut(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addOnlySwaps(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addOnlyBridges(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNonce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.QuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.QuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.QuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.QuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.Token.serializeBinaryToWriter
    );
  }
  f = message.getDstToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.Token.serializeBinaryToWriter
    );
  }
  f = message.getAmountIn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSlippageTolerance();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNativeOut();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOnlySwapsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getOnlyBridgesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
};


/**
 * optional Token src_token = 1;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.QuoteRequest.prototype.getSrcToken = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, common_pb.Token, 1));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.QuoteRequest} returns this
*/
proto.chainhop.QuoteRequest.prototype.setSrcToken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.clearSrcToken = function() {
  return this.setSrcToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.QuoteRequest.prototype.hasSrcToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Token dst_token = 2;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.QuoteRequest.prototype.getDstToken = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, common_pb.Token, 2));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.QuoteRequest} returns this
*/
proto.chainhop.QuoteRequest.prototype.setDstToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.clearDstToken = function() {
  return this.setDstToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.QuoteRequest.prototype.hasDstToken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string amount_in = 3;
 * @return {string}
 */
proto.chainhop.QuoteRequest.prototype.getAmountIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.setAmountIn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 slippage_tolerance = 4;
 * @return {number}
 */
proto.chainhop.QuoteRequest.prototype.getSlippageTolerance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.setSlippageTolerance = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool native_out = 5;
 * @return {boolean}
 */
proto.chainhop.QuoteRequest.prototype.getNativeOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.setNativeOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated string only_swaps = 6;
 * @return {!Array<string>}
 */
proto.chainhop.QuoteRequest.prototype.getOnlySwapsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.setOnlySwapsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.addOnlySwaps = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.clearOnlySwapsList = function() {
  return this.setOnlySwapsList([]);
};


/**
 * repeated string only_bridges = 7;
 * @return {!Array<string>}
 */
proto.chainhop.QuoteRequest.prototype.getOnlyBridgesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.setOnlyBridgesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.addOnlyBridges = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.clearOnlyBridgesList = function() {
  return this.setOnlyBridgesList([]);
};


/**
 * optional uint64 nonce = 8;
 * @return {number}
 */
proto.chainhop.QuoteRequest.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.QuoteRequest} returns this
 */
proto.chainhop.QuoteRequest.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.QuoteResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.QuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.QuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.QuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.QuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f),
    srcTokenUsdPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    dstTokenUsdPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    pathsList: jspb.Message.toObjectList(msg.getPathsList(),
    common_pb.Path.toObject, includeInstance),
    nonce: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.QuoteResponse}
 */
proto.chainhop.QuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.QuoteResponse;
  return proto.chainhop.QuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.QuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.QuoteResponse}
 */
proto.chainhop.QuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSrcTokenUsdPrice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDstTokenUsdPrice(value);
      break;
    case 4:
      var value = new common_pb.Path;
      reader.readMessage(value,common_pb.Path.deserializeBinaryFromReader);
      msg.addPaths(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNonce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.QuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.QuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.QuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.QuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getSrcTokenUsdPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDstTokenUsdPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPathsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_pb.Path.serializeBinaryToWriter
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.QuoteResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.QuoteResponse} returns this
*/
proto.chainhop.QuoteResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.QuoteResponse} returns this
 */
proto.chainhop.QuoteResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.QuoteResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double src_token_usd_price = 2;
 * @return {number}
 */
proto.chainhop.QuoteResponse.prototype.getSrcTokenUsdPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.QuoteResponse} returns this
 */
proto.chainhop.QuoteResponse.prototype.setSrcTokenUsdPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double dst_token_usd_price = 3;
 * @return {number}
 */
proto.chainhop.QuoteResponse.prototype.getDstTokenUsdPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.QuoteResponse} returns this
 */
proto.chainhop.QuoteResponse.prototype.setDstTokenUsdPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * repeated Path paths = 4;
 * @return {!Array<!proto.chainhop.Path>}
 */
proto.chainhop.QuoteResponse.prototype.getPathsList = function() {
  return /** @type{!Array<!proto.chainhop.Path>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Path, 4));
};


/**
 * @param {!Array<!proto.chainhop.Path>} value
 * @return {!proto.chainhop.QuoteResponse} returns this
*/
proto.chainhop.QuoteResponse.prototype.setPathsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.chainhop.Path=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chainhop.Path}
 */
proto.chainhop.QuoteResponse.prototype.addPaths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.chainhop.Path, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.QuoteResponse} returns this
 */
proto.chainhop.QuoteResponse.prototype.clearPathsList = function() {
  return this.setPathsList([]);
};


/**
 * optional uint64 nonce = 5;
 * @return {number}
 */
proto.chainhop.QuoteResponse.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.QuoteResponse} returns this
 */
proto.chainhop.QuoteResponse.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.SwapRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.SwapRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.SwapRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.SwapRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.SwapRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    common_pb.Step.toObject, includeInstance),
    amountIn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    slippageTolerance: jspb.Message.getFieldWithDefault(msg, 3, 0),
    nativeIn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    nativeOut: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    receiver: jspb.Message.getFieldWithDefault(msg, 6, ""),
    nonce: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sender: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.SwapRequest}
 */
proto.chainhop.SwapRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.SwapRequest;
  return proto.chainhop.SwapRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.SwapRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.SwapRequest}
 */
proto.chainhop.SwapRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.Step;
      reader.readMessage(value,common_pb.Step.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountIn(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSlippageTolerance(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNativeIn(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNativeOut(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiver(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNonce(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSender(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.SwapRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.SwapRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.SwapRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.SwapRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_pb.Step.serializeBinaryToWriter
    );
  }
  f = message.getAmountIn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSlippageTolerance();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getNativeIn();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNativeOut();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getReceiver();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * repeated Step steps = 1;
 * @return {!Array<!proto.chainhop.Step>}
 */
proto.chainhop.SwapRequest.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.chainhop.Step>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.Step, 1));
};


/**
 * @param {!Array<!proto.chainhop.Step>} value
 * @return {!proto.chainhop.SwapRequest} returns this
*/
proto.chainhop.SwapRequest.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.chainhop.Step=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chainhop.Step}
 */
proto.chainhop.SwapRequest.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.chainhop.Step, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.SwapRequest} returns this
 */
proto.chainhop.SwapRequest.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};


/**
 * optional string amount_in = 2;
 * @return {string}
 */
proto.chainhop.SwapRequest.prototype.getAmountIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.SwapRequest} returns this
 */
proto.chainhop.SwapRequest.prototype.setAmountIn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 slippage_tolerance = 3;
 * @return {number}
 */
proto.chainhop.SwapRequest.prototype.getSlippageTolerance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.SwapRequest} returns this
 */
proto.chainhop.SwapRequest.prototype.setSlippageTolerance = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool native_in = 4;
 * @return {boolean}
 */
proto.chainhop.SwapRequest.prototype.getNativeIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chainhop.SwapRequest} returns this
 */
proto.chainhop.SwapRequest.prototype.setNativeIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool native_out = 5;
 * @return {boolean}
 */
proto.chainhop.SwapRequest.prototype.getNativeOut = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chainhop.SwapRequest} returns this
 */
proto.chainhop.SwapRequest.prototype.setNativeOut = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string receiver = 6;
 * @return {string}
 */
proto.chainhop.SwapRequest.prototype.getReceiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.SwapRequest} returns this
 */
proto.chainhop.SwapRequest.prototype.setReceiver = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint64 nonce = 7;
 * @return {number}
 */
proto.chainhop.SwapRequest.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.SwapRequest} returns this
 */
proto.chainhop.SwapRequest.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string sender = 8;
 * @return {string}
 */
proto.chainhop.SwapRequest.prototype.getSender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.SwapRequest} returns this
 */
proto.chainhop.SwapRequest.prototype.setSender = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.SwapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.SwapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.SwapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.SwapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f),
    txData: jspb.Message.getFieldWithDefault(msg, 2, ""),
    txValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    path: (f = msg.getPath()) && common_pb.Path.toObject(includeInstance, f),
    nonce: jspb.Message.getFieldWithDefault(msg, 5, 0),
    swapId: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.SwapResponse}
 */
proto.chainhop.SwapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.SwapResponse;
  return proto.chainhop.SwapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.SwapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.SwapResponse}
 */
proto.chainhop.SwapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxValue(value);
      break;
    case 4:
      var value = new common_pb.Path;
      reader.readMessage(value,common_pb.Path.deserializeBinaryFromReader);
      msg.setPath(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNonce(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.SwapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.SwapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.SwapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.SwapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getTxData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTxValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPath();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.Path.serializeBinaryToWriter
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getSwapId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.SwapResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.SwapResponse} returns this
*/
proto.chainhop.SwapResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.SwapResponse} returns this
 */
proto.chainhop.SwapResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.SwapResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string tx_data = 2;
 * @return {string}
 */
proto.chainhop.SwapResponse.prototype.getTxData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.SwapResponse} returns this
 */
proto.chainhop.SwapResponse.prototype.setTxData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tx_value = 3;
 * @return {string}
 */
proto.chainhop.SwapResponse.prototype.getTxValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.SwapResponse} returns this
 */
proto.chainhop.SwapResponse.prototype.setTxValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Path path = 4;
 * @return {?proto.chainhop.Path}
 */
proto.chainhop.SwapResponse.prototype.getPath = function() {
  return /** @type{?proto.chainhop.Path} */ (
    jspb.Message.getWrapperField(this, common_pb.Path, 4));
};


/**
 * @param {?proto.chainhop.Path|undefined} value
 * @return {!proto.chainhop.SwapResponse} returns this
*/
proto.chainhop.SwapResponse.prototype.setPath = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.SwapResponse} returns this
 */
proto.chainhop.SwapResponse.prototype.clearPath = function() {
  return this.setPath(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.SwapResponse.prototype.hasPath = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 nonce = 5;
 * @return {number}
 */
proto.chainhop.SwapResponse.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.SwapResponse} returns this
 */
proto.chainhop.SwapResponse.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string swap_id = 6;
 * @return {string}
 */
proto.chainhop.SwapResponse.prototype.getSwapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.SwapResponse} returns this
 */
proto.chainhop.SwapResponse.prototype.setSwapId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetHistoryByTxRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetHistoryByTxRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetHistoryByTxRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetHistoryByTxRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcTxHash: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetHistoryByTxRequest}
 */
proto.chainhop.GetHistoryByTxRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetHistoryByTxRequest;
  return proto.chainhop.GetHistoryByTxRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetHistoryByTxRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetHistoryByTxRequest}
 */
proto.chainhop.GetHistoryByTxRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcTxHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetHistoryByTxRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetHistoryByTxRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetHistoryByTxRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetHistoryByTxRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcTxHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string src_tx_hash = 1;
 * @return {string}
 */
proto.chainhop.GetHistoryByTxRequest.prototype.getSrcTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.GetHistoryByTxRequest} returns this
 */
proto.chainhop.GetHistoryByTxRequest.prototype.setSrcTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetHistoryByTxResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetHistoryByTxResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetHistoryByTxResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetHistoryByTxResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    record: (f = msg.getRecord()) && common_pb.HopHistory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetHistoryByTxResponse}
 */
proto.chainhop.GetHistoryByTxResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetHistoryByTxResponse;
  return proto.chainhop.GetHistoryByTxResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetHistoryByTxResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetHistoryByTxResponse}
 */
proto.chainhop.GetHistoryByTxResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.HopHistory;
      reader.readMessage(value,common_pb.HopHistory.deserializeBinaryFromReader);
      msg.setRecord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetHistoryByTxResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetHistoryByTxResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetHistoryByTxResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetHistoryByTxResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecord();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.HopHistory.serializeBinaryToWriter
    );
  }
};


/**
 * optional HopHistory record = 1;
 * @return {?proto.chainhop.HopHistory}
 */
proto.chainhop.GetHistoryByTxResponse.prototype.getRecord = function() {
  return /** @type{?proto.chainhop.HopHistory} */ (
    jspb.Message.getWrapperField(this, common_pb.HopHistory, 1));
};


/**
 * @param {?proto.chainhop.HopHistory|undefined} value
 * @return {!proto.chainhop.GetHistoryByTxResponse} returns this
*/
proto.chainhop.GetHistoryByTxResponse.prototype.setRecord = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.GetHistoryByTxResponse} returns this
 */
proto.chainhop.GetHistoryByTxResponse.prototype.clearRecord = function() {
  return this.setRecord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.GetHistoryByTxResponse.prototype.hasRecord = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    addr: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetHistoryRequest}
 */
proto.chainhop.GetHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetHistoryRequest;
  return proto.chainhop.GetHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetHistoryRequest}
 */
proto.chainhop.GetHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string next_page_token = 1;
 * @return {string}
 */
proto.chainhop.GetHistoryRequest.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.GetHistoryRequest} returns this
 */
proto.chainhop.GetHistoryRequest.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 page_size = 2;
 * @return {number}
 */
proto.chainhop.GetHistoryRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.GetHistoryRequest} returns this
 */
proto.chainhop.GetHistoryRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string addr = 3;
 * @return {string}
 */
proto.chainhop.GetHistoryRequest.prototype.getAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.GetHistoryRequest} returns this
 */
proto.chainhop.GetHistoryRequest.prototype.setAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.GetHistoryResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f),
    historyList: jspb.Message.toObjectList(msg.getHistoryList(),
    common_pb.HopHistory.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currentSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalSize: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetHistoryResponse}
 */
proto.chainhop.GetHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetHistoryResponse;
  return proto.chainhop.GetHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetHistoryResponse}
 */
proto.chainhop.GetHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = new common_pb.HopHistory;
      reader.readMessage(value,common_pb.HopHistory.deserializeBinaryFromReader);
      msg.addHistory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentSize(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_pb.HopHistory.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrentSize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.GetHistoryResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.GetHistoryResponse} returns this
*/
proto.chainhop.GetHistoryResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.GetHistoryResponse} returns this
 */
proto.chainhop.GetHistoryResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.GetHistoryResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated HopHistory history = 2;
 * @return {!Array<!proto.chainhop.HopHistory>}
 */
proto.chainhop.GetHistoryResponse.prototype.getHistoryList = function() {
  return /** @type{!Array<!proto.chainhop.HopHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_pb.HopHistory, 2));
};


/**
 * @param {!Array<!proto.chainhop.HopHistory>} value
 * @return {!proto.chainhop.GetHistoryResponse} returns this
*/
proto.chainhop.GetHistoryResponse.prototype.setHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.chainhop.HopHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chainhop.HopHistory}
 */
proto.chainhop.GetHistoryResponse.prototype.addHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.chainhop.HopHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.GetHistoryResponse} returns this
 */
proto.chainhop.GetHistoryResponse.prototype.clearHistoryList = function() {
  return this.setHistoryList([]);
};


/**
 * optional string next_page_token = 3;
 * @return {string}
 */
proto.chainhop.GetHistoryResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.GetHistoryResponse} returns this
 */
proto.chainhop.GetHistoryResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 current_size = 4;
 * @return {number}
 */
proto.chainhop.GetHistoryResponse.prototype.getCurrentSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.GetHistoryResponse} returns this
 */
proto.chainhop.GetHistoryResponse.prototype.setCurrentSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 total_size = 5;
 * @return {number}
 */
proto.chainhop.GetHistoryResponse.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.GetHistoryResponse} returns this
 */
proto.chainhop.GetHistoryResponse.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    swapId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetStatusRequest}
 */
proto.chainhop.GetStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetStatusRequest;
  return proto.chainhop.GetStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetStatusRequest}
 */
proto.chainhop.GetStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string swap_id = 1;
 * @return {string}
 */
proto.chainhop.GetStatusRequest.prototype.getSwapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.GetStatusRequest} returns this
 */
proto.chainhop.GetStatusRequest.prototype.setSwapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f),
    swapStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cbrTransferId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    srcBlockTxLink: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dstBlockTxLink: jspb.Message.getFieldWithDefault(msg, 5, ""),
    swapType: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetStatusResponse}
 */
proto.chainhop.GetStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetStatusResponse;
  return proto.chainhop.GetStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetStatusResponse}
 */
proto.chainhop.GetStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = /** @type {!proto.chainhop.SwapStatus} */ (reader.readEnum());
      msg.setSwapStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCbrTransferId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcBlockTxLink(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstBlockTxLink(value);
      break;
    case 6:
      var value = /** @type {!proto.chainhop.SwapType} */ (reader.readEnum());
      msg.setSwapType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getSwapStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCbrTransferId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSrcBlockTxLink();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDstBlockTxLink();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSwapType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.GetStatusResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.GetStatusResponse} returns this
*/
proto.chainhop.GetStatusResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.GetStatusResponse} returns this
 */
proto.chainhop.GetStatusResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.GetStatusResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SwapStatus swap_status = 2;
 * @return {!proto.chainhop.SwapStatus}
 */
proto.chainhop.GetStatusResponse.prototype.getSwapStatus = function() {
  return /** @type {!proto.chainhop.SwapStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.chainhop.SwapStatus} value
 * @return {!proto.chainhop.GetStatusResponse} returns this
 */
proto.chainhop.GetStatusResponse.prototype.setSwapStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string cbr_transfer_id = 3;
 * @return {string}
 */
proto.chainhop.GetStatusResponse.prototype.getCbrTransferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.GetStatusResponse} returns this
 */
proto.chainhop.GetStatusResponse.prototype.setCbrTransferId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string src_block_tx_link = 4;
 * @return {string}
 */
proto.chainhop.GetStatusResponse.prototype.getSrcBlockTxLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.GetStatusResponse} returns this
 */
proto.chainhop.GetStatusResponse.prototype.setSrcBlockTxLink = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dst_block_tx_link = 5;
 * @return {string}
 */
proto.chainhop.GetStatusResponse.prototype.getDstBlockTxLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.GetStatusResponse} returns this
 */
proto.chainhop.GetStatusResponse.prototype.setDstBlockTxLink = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SwapType swap_type = 6;
 * @return {!proto.chainhop.SwapType}
 */
proto.chainhop.GetStatusResponse.prototype.getSwapType = function() {
  return /** @type {!proto.chainhop.SwapType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.chainhop.SwapType} value
 * @return {!proto.chainhop.GetStatusResponse} returns this
 */
proto.chainhop.GetStatusResponse.prototype.setSwapType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcChainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dstChainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bridgeType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    swapType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest;
  return proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSrcChainId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDstChainId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBridgeType(value);
      break;
    case 4:
      var value = /** @type {!proto.chainhop.SwapType} */ (reader.readEnum());
      msg.setSwapType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getDstChainId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getBridgeType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSwapType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional uint64 src_chain_id = 1;
 * @return {number}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.getSrcChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest} returns this
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.setSrcChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 dst_chain_id = 2;
 * @return {number}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.getDstChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest} returns this
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.setDstChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string bridge_type = 3;
 * @return {string}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.getBridgeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest} returns this
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.setBridgeType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SwapType swap_type = 4;
 * @return {!proto.chainhop.SwapType}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.getSwapType = function() {
  return /** @type {!proto.chainhop.SwapType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.chainhop.SwapType} value
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest} returns this
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryRequest.prototype.setSwapType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f),
    medianTransferLatencyInSecond: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse;
  return proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMedianTransferLatencyInSecond(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getMedianTransferLatencyInSecond();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse} returns this
*/
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse} returns this
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double median_transfer_latency_in_second = 2;
 * @return {number}
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.prototype.getMedianTransferLatencyInSecond = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse} returns this
 */
proto.chainhop.GetLatest7DayTransferLatencyForQueryResponse.prototype.setMedianTransferLatencyInSecond = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.RfqQuoteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.RfqQuoteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.RfqQuoteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqQuoteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: (f = msg.getPrice()) && common_pb.RfqPrice.toObject(includeInstance, f),
    mmId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sender: jspb.Message.getFieldWithDefault(msg, 3, ""),
    receiver: jspb.Message.getFieldWithDefault(msg, 4, ""),
    refundTo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    srcDeadline: jspb.Message.getFieldWithDefault(msg, 6, 0),
    dstDeadline: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dstNative: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    srcNative: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.RfqQuoteRequest}
 */
proto.chainhop.RfqQuoteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.RfqQuoteRequest;
  return proto.chainhop.RfqQuoteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.RfqQuoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.RfqQuoteRequest}
 */
proto.chainhop.RfqQuoteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_pb.RfqPrice;
      reader.readMessage(value,common_pb.RfqPrice.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMmId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSender(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiver(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundTo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSrcDeadline(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDstDeadline(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDstNative(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSrcNative(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.RfqQuoteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.RfqQuoteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.RfqQuoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqQuoteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_pb.RfqPrice.serializeBinaryToWriter
    );
  }
  f = message.getMmId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReceiver();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRefundTo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSrcDeadline();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getDstDeadline();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getDstNative();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSrcNative();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional RfqPrice price = 1;
 * @return {?proto.chainhop.RfqPrice}
 */
proto.chainhop.RfqQuoteRequest.prototype.getPrice = function() {
  return /** @type{?proto.chainhop.RfqPrice} */ (
    jspb.Message.getWrapperField(this, common_pb.RfqPrice, 1));
};


/**
 * @param {?proto.chainhop.RfqPrice|undefined} value
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
*/
proto.chainhop.RfqQuoteRequest.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
 */
proto.chainhop.RfqQuoteRequest.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqQuoteRequest.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string mm_id = 2;
 * @return {string}
 */
proto.chainhop.RfqQuoteRequest.prototype.getMmId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
 */
proto.chainhop.RfqQuoteRequest.prototype.setMmId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sender = 3;
 * @return {string}
 */
proto.chainhop.RfqQuoteRequest.prototype.getSender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
 */
proto.chainhop.RfqQuoteRequest.prototype.setSender = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string receiver = 4;
 * @return {string}
 */
proto.chainhop.RfqQuoteRequest.prototype.getReceiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
 */
proto.chainhop.RfqQuoteRequest.prototype.setReceiver = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string refund_to = 5;
 * @return {string}
 */
proto.chainhop.RfqQuoteRequest.prototype.getRefundTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
 */
proto.chainhop.RfqQuoteRequest.prototype.setRefundTo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint64 src_deadline = 6;
 * @return {number}
 */
proto.chainhop.RfqQuoteRequest.prototype.getSrcDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
 */
proto.chainhop.RfqQuoteRequest.prototype.setSrcDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 dst_deadline = 7;
 * @return {number}
 */
proto.chainhop.RfqQuoteRequest.prototype.getDstDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
 */
proto.chainhop.RfqQuoteRequest.prototype.setDstDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool dst_native = 8;
 * @return {boolean}
 */
proto.chainhop.RfqQuoteRequest.prototype.getDstNative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
 */
proto.chainhop.RfqQuoteRequest.prototype.setDstNative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool src_native = 9;
 * @return {boolean}
 */
proto.chainhop.RfqQuoteRequest.prototype.getSrcNative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chainhop.RfqQuoteRequest} returns this
 */
proto.chainhop.RfqQuoteRequest.prototype.setSrcNative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.RfqQuoteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.RfqQuoteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.RfqQuoteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqQuoteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && proto.chainhop.RfqQuote.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.RfqQuoteResponse}
 */
proto.chainhop.RfqQuoteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.RfqQuoteResponse;
  return proto.chainhop.RfqQuoteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.RfqQuoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.RfqQuoteResponse}
 */
proto.chainhop.RfqQuoteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = new proto.chainhop.RfqQuote;
      reader.readMessage(value,proto.chainhop.RfqQuote.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.RfqQuoteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.RfqQuoteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.RfqQuoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqQuoteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.chainhop.RfqQuote.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.RfqQuoteResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.RfqQuoteResponse} returns this
*/
proto.chainhop.RfqQuoteResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqQuoteResponse} returns this
 */
proto.chainhop.RfqQuoteResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqQuoteResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RfqQuote quote = 2;
 * @return {?proto.chainhop.RfqQuote}
 */
proto.chainhop.RfqQuoteResponse.prototype.getQuote = function() {
  return /** @type{?proto.chainhop.RfqQuote} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.RfqQuote, 2));
};


/**
 * @param {?proto.chainhop.RfqQuote|undefined} value
 * @return {!proto.chainhop.RfqQuoteResponse} returns this
*/
proto.chainhop.RfqQuoteResponse.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqQuoteResponse} returns this
 */
proto.chainhop.RfqQuoteResponse.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqQuoteResponse.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.RfqQuote.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.RfqQuote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.RfqQuote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqQuote.toObject = function(includeInstance, msg) {
  var f, obj = {
    hash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    srcToken: (f = msg.getSrcToken()) && common_pb.Token.toObject(includeInstance, f),
    srcAmount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    srcReleaseAmount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dstToken: (f = msg.getDstToken()) && common_pb.Token.toObject(includeInstance, f),
    dstAmount: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dstDeadline: jspb.Message.getFieldWithDefault(msg, 7, 0),
    nonce: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sender: jspb.Message.getFieldWithDefault(msg, 9, ""),
    receiver: jspb.Message.getFieldWithDefault(msg, 10, ""),
    refundTo: jspb.Message.getFieldWithDefault(msg, 11, ""),
    mmAddr: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.RfqQuote}
 */
proto.chainhop.RfqQuote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.RfqQuote;
  return proto.chainhop.RfqQuote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.RfqQuote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.RfqQuote}
 */
proto.chainhop.RfqQuote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    case 2:
      var value = new common_pb.Token;
      reader.readMessage(value,common_pb.Token.deserializeBinaryFromReader);
      msg.setSrcToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcReleaseAmount(value);
      break;
    case 5:
      var value = new common_pb.Token;
      reader.readMessage(value,common_pb.Token.deserializeBinaryFromReader);
      msg.setDstToken(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstAmount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDstDeadline(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNonce(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSender(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiver(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundTo(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMmAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.RfqQuote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.RfqQuote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.RfqQuote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqQuote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSrcToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_pb.Token.serializeBinaryToWriter
    );
  }
  f = message.getSrcAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSrcReleaseAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDstToken();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_pb.Token.serializeBinaryToWriter
    );
  }
  f = message.getDstAmount();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDstDeadline();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReceiver();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRefundTo();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMmAddr();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string hash = 1;
 * @return {string}
 */
proto.chainhop.RfqQuote.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Token src_token = 2;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.RfqQuote.prototype.getSrcToken = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, common_pb.Token, 2));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.RfqQuote} returns this
*/
proto.chainhop.RfqQuote.prototype.setSrcToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.clearSrcToken = function() {
  return this.setSrcToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqQuote.prototype.hasSrcToken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string src_amount = 3;
 * @return {string}
 */
proto.chainhop.RfqQuote.prototype.getSrcAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setSrcAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string src_release_amount = 4;
 * @return {string}
 */
proto.chainhop.RfqQuote.prototype.getSrcReleaseAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setSrcReleaseAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Token dst_token = 5;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.RfqQuote.prototype.getDstToken = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, common_pb.Token, 5));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.RfqQuote} returns this
*/
proto.chainhop.RfqQuote.prototype.setDstToken = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.clearDstToken = function() {
  return this.setDstToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqQuote.prototype.hasDstToken = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string dst_amount = 6;
 * @return {string}
 */
proto.chainhop.RfqQuote.prototype.getDstAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setDstAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint64 dst_deadline = 7;
 * @return {number}
 */
proto.chainhop.RfqQuote.prototype.getDstDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setDstDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 nonce = 8;
 * @return {number}
 */
proto.chainhop.RfqQuote.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string sender = 9;
 * @return {string}
 */
proto.chainhop.RfqQuote.prototype.getSender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setSender = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string receiver = 10;
 * @return {string}
 */
proto.chainhop.RfqQuote.prototype.getReceiver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setReceiver = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string refund_to = 11;
 * @return {string}
 */
proto.chainhop.RfqQuote.prototype.getRefundTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setRefundTo = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string mm_addr = 12;
 * @return {string}
 */
proto.chainhop.RfqQuote.prototype.getMmAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqQuote} returns this
 */
proto.chainhop.RfqQuote.prototype.setMmAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.RfqRefundRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.RfqRefundRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.RfqRefundRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqRefundRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    swapId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.RfqRefundRequest}
 */
proto.chainhop.RfqRefundRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.RfqRefundRequest;
  return proto.chainhop.RfqRefundRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.RfqRefundRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.RfqRefundRequest}
 */
proto.chainhop.RfqRefundRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.RfqRefundRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.RfqRefundRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.RfqRefundRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqRefundRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string swap_id = 1;
 * @return {string}
 */
proto.chainhop.RfqRefundRequest.prototype.getSwapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqRefundRequest} returns this
 */
proto.chainhop.RfqRefundRequest.prototype.setSwapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.RfqRefundResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.RfqRefundResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.RfqRefundResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqRefundResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && proto.chainhop.RfqQuote.toObject(includeInstance, f),
    execMsgCallData: msg.getExecMsgCallData_asB64(),
    srcNative: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.RfqRefundResponse}
 */
proto.chainhop.RfqRefundResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.RfqRefundResponse;
  return proto.chainhop.RfqRefundResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.RfqRefundResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.RfqRefundResponse}
 */
proto.chainhop.RfqRefundResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    case 2:
      var value = new proto.chainhop.RfqQuote;
      reader.readMessage(value,proto.chainhop.RfqQuote.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setExecMsgCallData(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSrcNative(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.RfqRefundResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.RfqRefundResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.RfqRefundResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqRefundResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.chainhop.RfqQuote.serializeBinaryToWriter
    );
  }
  f = message.getExecMsgCallData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getSrcNative();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.RfqRefundResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.RfqRefundResponse} returns this
*/
proto.chainhop.RfqRefundResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqRefundResponse} returns this
 */
proto.chainhop.RfqRefundResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqRefundResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RfqQuote quote = 2;
 * @return {?proto.chainhop.RfqQuote}
 */
proto.chainhop.RfqRefundResponse.prototype.getQuote = function() {
  return /** @type{?proto.chainhop.RfqQuote} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.RfqQuote, 2));
};


/**
 * @param {?proto.chainhop.RfqQuote|undefined} value
 * @return {!proto.chainhop.RfqRefundResponse} returns this
*/
proto.chainhop.RfqRefundResponse.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqRefundResponse} returns this
 */
proto.chainhop.RfqRefundResponse.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqRefundResponse.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes exec_msg_call_data = 3;
 * @return {!(string|Uint8Array)}
 */
proto.chainhop.RfqRefundResponse.prototype.getExecMsgCallData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes exec_msg_call_data = 3;
 * This is a type-conversion wrapper around `getExecMsgCallData()`
 * @return {string}
 */
proto.chainhop.RfqRefundResponse.prototype.getExecMsgCallData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getExecMsgCallData()));
};


/**
 * optional bytes exec_msg_call_data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getExecMsgCallData()`
 * @return {!Uint8Array}
 */
proto.chainhop.RfqRefundResponse.prototype.getExecMsgCallData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getExecMsgCallData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.chainhop.RfqRefundResponse} returns this
 */
proto.chainhop.RfqRefundResponse.prototype.setExecMsgCallData = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bool src_native = 4;
 * @return {boolean}
 */
proto.chainhop.RfqRefundResponse.prototype.getSrcNative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.chainhop.RfqRefundResponse} returns this
 */
proto.chainhop.RfqRefundResponse.prototype.setSrcNative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.RfqMarkAsSubmittingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.RfqMarkAsSubmittingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.RfqMarkAsSubmittingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqMarkAsSubmittingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    quoteHash: jspb.Message.getFieldWithDefault(msg, 1, ""),
    srcTx: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.RfqMarkAsSubmittingRequest}
 */
proto.chainhop.RfqMarkAsSubmittingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.RfqMarkAsSubmittingRequest;
  return proto.chainhop.RfqMarkAsSubmittingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.RfqMarkAsSubmittingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.RfqMarkAsSubmittingRequest}
 */
proto.chainhop.RfqMarkAsSubmittingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuoteHash(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcTx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.RfqMarkAsSubmittingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.RfqMarkAsSubmittingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.RfqMarkAsSubmittingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqMarkAsSubmittingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuoteHash();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSrcTx();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string quote_hash = 1;
 * @return {string}
 */
proto.chainhop.RfqMarkAsSubmittingRequest.prototype.getQuoteHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqMarkAsSubmittingRequest} returns this
 */
proto.chainhop.RfqMarkAsSubmittingRequest.prototype.setQuoteHash = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string src_tx = 2;
 * @return {string}
 */
proto.chainhop.RfqMarkAsSubmittingRequest.prototype.getSrcTx = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqMarkAsSubmittingRequest} returns this
 */
proto.chainhop.RfqMarkAsSubmittingRequest.prototype.setSrcTx = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.RfqMarkAsSubmittingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.RfqMarkAsSubmittingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.RfqMarkAsSubmittingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqMarkAsSubmittingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    err: (f = msg.getErr()) && error_pb.ErrMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.RfqMarkAsSubmittingResponse}
 */
proto.chainhop.RfqMarkAsSubmittingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.RfqMarkAsSubmittingResponse;
  return proto.chainhop.RfqMarkAsSubmittingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.RfqMarkAsSubmittingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.RfqMarkAsSubmittingResponse}
 */
proto.chainhop.RfqMarkAsSubmittingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new error_pb.ErrMsg;
      reader.readMessage(value,error_pb.ErrMsg.deserializeBinaryFromReader);
      msg.setErr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.RfqMarkAsSubmittingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.RfqMarkAsSubmittingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.RfqMarkAsSubmittingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqMarkAsSubmittingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      error_pb.ErrMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrMsg err = 1;
 * @return {?proto.chainhop.ErrMsg}
 */
proto.chainhop.RfqMarkAsSubmittingResponse.prototype.getErr = function() {
  return /** @type{?proto.chainhop.ErrMsg} */ (
    jspb.Message.getWrapperField(this, error_pb.ErrMsg, 1));
};


/**
 * @param {?proto.chainhop.ErrMsg|undefined} value
 * @return {!proto.chainhop.RfqMarkAsSubmittingResponse} returns this
*/
proto.chainhop.RfqMarkAsSubmittingResponse.prototype.setErr = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqMarkAsSubmittingResponse} returns this
 */
proto.chainhop.RfqMarkAsSubmittingResponse.prototype.clearErr = function() {
  return this.setErr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqMarkAsSubmittingResponse.prototype.hasErr = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.chainhop);
