import { createUseStyles } from "react-jss";
import { Button } from "antd";
import cla from "classnames";
import 'animate.css';
import ScrollLoad from "../components/ScrollLoad";

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  section: {
    marginTop: props => props.isMobile ? 120 : 300,
  },
  middle: {
    width: props => props.isMobile ? 'calc(100% - 60px)' : 1300,
    border: '2px solid #000',
    marginTop: 160,
    margin: '0 auto'
  },
  middleInner: {
    padding: props => props.isMobile ? '68px 0.2rem' : '100px 40px',
  },
  middleTitle: {
    fontWeight: 700,
    fontSize: props => props.isMobile ? '0.45rem' : 96,
    lineHeight: props => props.isMobile ? '0.57rem' : '115px',
  },
  middleDes: {
    marginTop: 48,
    fontSize: props => props.isMobile ? '0.2rem' : 24,
    lineHeight: props => props.isMobile ? '0.24rem' : '28px',
    width: props => props.isMobile ? '100%' : 1100,
    margin: '0 auto',
  },
  btnWrapper: {
    width: props => props.isMobile ? '100%' : 484,
    display: 'flex',
    flexDirection: props => props.isMobile ? 'column' : 'start',
    justifyContent: 'space-around',
    margin: '0 auto'
  },
  btn: {
    cursor: 'pointer',
    height: props => props.isMobile ? 44 : 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: props => props.isMobile ? 16 : 24,
    borderRadius: 0,
    width: props => props.isMobile ? '100%' : 'auto',
    marginTop: 40,
    fontWeight: 700,
    padding: props => props.isMobile ? '0' : '16px 24px',
  },

  integrate: {
    backgroundColor: '#86C2FF',
    color: '#000',

    '&:hover': {
      backgroundColor: '#86C2FF !important',
      color: '#000 !important',
      opacity: 0.8
    }
  },
  contact: {
    backgroundColor: '#000',
    color: '#fff',
    marginTop: props => props.isMobile ? 20 : 40,

    '&:hover': {
      backgroundColor: '#000 !important',
      color: '#fff !important',
      opacity: 0.8
    }
  },
  divider: {
    height: 10,
    border: 'none',
    borderBottom: '2px solid #000',
  },
  bottom: {
    margin: '0 auto',
    backgroundColor: '#E7F3FF',
    marginTop: 160,
    padding: props => props.isMobile ? '80px 8%' : '138px calc(50% - 650px)',
  },
  bottomTitle: {
    fontWeight: 600,
    fontSize: props => props.isMobile ? '0.32rem' : 56,
    lineHeight:  props => props.isMobile ? '38px' : '76px',
    margin: '0 auto'
  },
  read: {
    backgroundColor: '#000',
    color: '#fff',
    width: 268,
    margin: '0 auto',
    marginTop: 30,

    '&:hover': {
      backgroundColor: '#000 !important',
      color: '#fff !important',
      opacity: '0.8 !important',
    }
  },
  rightPoint: {
    width: 20,
    marginLeft: 12,
  },
  pointMouseIn: {
    transition: 'all 0.6s',
    transform: 'scale(1.3)',
  },
  pointMouseOut: {
    transition: 'all 0.6s',
    transform: 'scale(1)',
  }
}))

export type IProps = {
  isMobile: boolean;
};
export default function SixSection(props: IProps): JSX.Element {
  const { isMobile = false } = props;
  const classes = useStyles({isMobile});
  const animateCls = 'animate__animated animate__fadeInUp animate__slower';
  const animateClsFast = 'animate__animated animate__fadeInUp animate__faster';
  const animateHeaderCls = 'animate__animated animate__fadeIn animate__slower';

  return (
    <div className={cla(classes.section)}> 
        <ScrollLoad>
          <div className={cla(classes.middle, animateClsFast)}>
            <div className={classes.divider}/>
            <div className={classes.middleInner}>
              <div className={cla(classes.middleTitle, animateHeaderCls)}>
                { isMobile ? <><div> Get started</div><div>now</div></> : <>Get started now</> }
              </div>
              <div className={cla(classes.middleDes, animateCls)}>
                Build your cross-chain dApp or wallet with ChainHop’s API to give your users access to seamless cross-chain swaps with the best rates and UX available
              </div>
              <div className={cla(classes.btnWrapper, animateCls)}>
                <Button type="primary" className={cla(classes.btn, classes.integrate)} onClick={() => window.open('https://docs.chainhop.exchange/developer/chainhop-open-api')}>
                  Integrate API
                </Button>
                <Button type="primary" className={cla(classes.btn, classes.contact)} onClick={() => window.open('https://form.typeform.com/to/vp9YO1I5')}>
                  Contact us
                  {/* {!isMobile && <img className={cla(classes.rightPoint, 'rightPoint1')} src={rightPoint} alt='' />} */}
                </Button>
                <div />
              </div>
            </div>
            <div className={classes.divider} style={{ border: 'none', borderTop: '2px solid #000' }}/>
          </div>
        </ScrollLoad>
      <div className={classes.bottom}>
        <ScrollLoad>
          <div className={cla(classes.bottomTitle, animateHeaderCls)}>
            {isMobile ? <>
              <div>Security is our</div>
              <div>utmost priority.</div> 
              <div>ChainHop is audited</div> 
              <div>by PeckShield and</div> 
              <div>CertiK.</div>
            </> : <>
              <h3 className={classes.bottomTitle}>Security is our utmost priority.</h3>
              <h3 className={classes.bottomTitle}>ChainHop is audited by PeckShield and CertiK.</h3>
            </>}
          </div>  
        </ScrollLoad>
        <ScrollLoad>
          <Button type="primary" className={cla(classes.btn, animateHeaderCls, classes.read)} onClick={() => window.open('https://github.com/chainhop-dex/chainhop-contracts/tree/main/audit')}>
            Read full reports
          </Button>
        </ScrollLoad>
      </div>
    </div>
  )
}