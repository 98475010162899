import { Button } from "antd";
import { createUseStyles } from "react-jss";
import cla from "classnames";
import Animation from '../components/Animation';
import 'animate.css';
import ScrollLoad from "../components/ScrollLoad";
import { CSSProperties } from "react";

const STATIC_FILE_PREFIX = '/lottie'

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  section: {
    width: props => props.isMobile ? '84%' : '100%',
    padding: props => props.isMobile ? 0 : '0 calc(50% - 650px)',
    margin: props => props.isMobile ? '0 auto' : 0,
    position: 'relative',
    overflow: 'hidden',
    height: props => props.isMobile ? 'auto' : 800,
    boxSizing: props => props.isMobile ? 'content-box' : 'border-box',
  },
  header: {
    width: props => props.isMobile ? '2.8rem' : 776,
    fontSize: props => props.isMobile ? '0.48rem' : 80,
    fontWeight: 700,
    marginTop: props => props.isMobile ? 160 : 244,
    textAlign: 'left',
  },
  des: {
    width: props => props.isMobile ? '2.8rem' : 600,
    marginTop: props => props.isMobile ? 40 : 32,
    fontSize: props => props.isMobile ? '0.18rem' : 18,
    fontWeight: 400,
    textAlign: 'left',
    color: '#444',
    lineHeight: '26px',
  },
  btnWrapper: {
    display: 'flex',
    marginTop: 32,
    justifyContent: props => props.isMobile ? 'space-around' : 'start',
  },
  btn: {
    cursor: 'pointer',
    padding: props => props.isMobile ? '4px 15px' : '16px 24px',
    height: props => props.isMobile ? 43 :50,
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    borderRadius: 0,
    fontWeight: 700,
  },
  integrate: {
    backgroundColor: '#000',
    color: '#fff',

    '&:hover': {
      backgroundColor: '#000 !important',
      color: '#fff !important',
      opacity: 0.8,
    }
  },
  contact: {
    backgroundColor: '#fff',
    color: '#000',
    border: '3px solid #000',
    marginLeft: 20,

    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#000 !important',
      opacity: 0.8,
    }
  },
}));

export type IProps = {
  isMobile: boolean;
};

export default function FirstSection(props: IProps): JSX.Element {
  const { isMobile } = props;
  const classes = useStyles({isMobile});
  // const animateHeaderCls = 'animate__animated animate__fadeIn animate__slow';
  // const animateCls = 'animate__animated animate__fadeInUp animate__slower';
  const animateCls = 'animate__animated animate__fadeInUp animate__slower';
  const animateFadeInCls = "animate__animated animate__fadeIn animate__slower";

  const pcAnimateStyle: CSSProperties = {
    position: 'absolute',
    top: 100,
    zIndex: -1,
    height: 690,
    width: 1040,
    left: 'calc(50% - 650px + 520px)',
  }

  const mobileAnimateStyle: CSSProperties = {
    zIndex: -1,
    width: '130vw',
    marginLeft: '-15vw',
    marginTop: 40,
  }
  return (
    <>
      <div className={cla(classes.section)}>
        <h1 className={cla(classes.header, animateFadeInCls)}>
          The Composable Multi-Chain Liquidity Network
        </h1>
        <ScrollLoad>
          <div className={cla(classes.des, animateCls)}>
            Offer your users a seamless and profitable cross-chain experience with ChainHop's omni-blockchain bridge and DEX aggregation.
          </div>
        </ScrollLoad>
        <ScrollLoad scrollHeight='500px'>
          <div className={cla(classes.btnWrapper, animateCls)}>
            <Button type="primary" className={cla(classes.btn, classes.integrate)} onClick={() => window.open ('https://docs.chainhop.exchange/developer/chainhop-open-api')}>
              Integrate API
            </Button>
            <Button type="primary" className={cla(classes.btn, classes.contact)} onClick={() => window.open('https://form.typeform.com/to/vp9YO1I5')}>
              Contact us
            </Button>
          </div>
        </ScrollLoad>
        {!isMobile && <Animation 
          renderer='svg'
          loop={true}
          canvasStyle={isMobile ? mobileAnimateStyle : pcAnimateStyle}
          path={`${STATIC_FILE_PREFIX}/data.json`}
        />}
      </div>
      <div style={{ width: '100%', overflowX: 'hidden' }}>
        {isMobile && <Animation 
          renderer='svg'
          loop={true}
          canvasStyle={isMobile ? mobileAnimateStyle : pcAnimateStyle}
          path={`${STATIC_FILE_PREFIX}/data.json`}
        />}
      </div>
    </>
  )
}