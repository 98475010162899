// source: error.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.chainhop.ErrCode', null, global);
goog.exportSymbol('proto.chainhop.ErrMinMaxSend', null, global);
goog.exportSymbol('proto.chainhop.ErrMsg', null, global);
goog.exportSymbol('proto.chainhop.ErrMsg.ErrCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.ErrMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.chainhop.ErrMsg.oneofGroups_);
};
goog.inherits(proto.chainhop.ErrMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.ErrMsg.displayName = 'proto.chainhop.ErrMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.ErrMinMaxSend = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.ErrMinMaxSend, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.ErrMinMaxSend.displayName = 'proto.chainhop.ErrMinMaxSend';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.chainhop.ErrMsg.oneofGroups_ = [[3]];

/**
 * @enum {number}
 */
proto.chainhop.ErrMsg.ErrCase = {
  ERR_NOT_SET: 0,
  ERR_MIN_MAX_SEND: 3
};

/**
 * @return {proto.chainhop.ErrMsg.ErrCase}
 */
proto.chainhop.ErrMsg.prototype.getErrCase = function() {
  return /** @type {proto.chainhop.ErrMsg.ErrCase} */(jspb.Message.computeOneofCase(this, proto.chainhop.ErrMsg.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.ErrMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.ErrMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.ErrMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.ErrMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    msg: jspb.Message.getFieldWithDefault(msg, 2, ""),
    errMinMaxSend: (f = msg.getErrMinMaxSend()) && proto.chainhop.ErrMinMaxSend.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.ErrMsg}
 */
proto.chainhop.ErrMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.ErrMsg;
  return proto.chainhop.ErrMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.ErrMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.ErrMsg}
 */
proto.chainhop.ErrMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.chainhop.ErrCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    case 3:
      var value = new proto.chainhop.ErrMinMaxSend;
      reader.readMessage(value,proto.chainhop.ErrMinMaxSend.deserializeBinaryFromReader);
      msg.setErrMinMaxSend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.ErrMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.ErrMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.ErrMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.ErrMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getErrMinMaxSend();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.chainhop.ErrMinMaxSend.serializeBinaryToWriter
    );
  }
};


/**
 * optional ErrCode code = 1;
 * @return {!proto.chainhop.ErrCode}
 */
proto.chainhop.ErrMsg.prototype.getCode = function() {
  return /** @type {!proto.chainhop.ErrCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.chainhop.ErrCode} value
 * @return {!proto.chainhop.ErrMsg} returns this
 */
proto.chainhop.ErrMsg.prototype.setCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string msg = 2;
 * @return {string}
 */
proto.chainhop.ErrMsg.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.ErrMsg} returns this
 */
proto.chainhop.ErrMsg.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ErrMinMaxSend err_min_max_send = 3;
 * @return {?proto.chainhop.ErrMinMaxSend}
 */
proto.chainhop.ErrMsg.prototype.getErrMinMaxSend = function() {
  return /** @type{?proto.chainhop.ErrMinMaxSend} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.ErrMinMaxSend, 3));
};


/**
 * @param {?proto.chainhop.ErrMinMaxSend|undefined} value
 * @return {!proto.chainhop.ErrMsg} returns this
*/
proto.chainhop.ErrMsg.prototype.setErrMinMaxSend = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.chainhop.ErrMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.ErrMsg} returns this
 */
proto.chainhop.ErrMsg.prototype.clearErrMinMaxSend = function() {
  return this.setErrMinMaxSend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.ErrMsg.prototype.hasErrMinMaxSend = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.ErrMinMaxSend.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.ErrMinMaxSend.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.ErrMinMaxSend} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.ErrMinMaxSend.toObject = function(includeInstance, msg) {
  var f, obj = {
    amountIn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    minSend: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxSend: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.ErrMinMaxSend}
 */
proto.chainhop.ErrMinMaxSend.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.ErrMinMaxSend;
  return proto.chainhop.ErrMinMaxSend.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.ErrMinMaxSend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.ErrMinMaxSend}
 */
proto.chainhop.ErrMinMaxSend.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountIn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinSend(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxSend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.ErrMinMaxSend.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.ErrMinMaxSend.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.ErrMinMaxSend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.ErrMinMaxSend.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmountIn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMinSend();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxSend();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string amount_in = 1;
 * @return {string}
 */
proto.chainhop.ErrMinMaxSend.prototype.getAmountIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.ErrMinMaxSend} returns this
 */
proto.chainhop.ErrMinMaxSend.prototype.setAmountIn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string min_send = 2;
 * @return {string}
 */
proto.chainhop.ErrMinMaxSend.prototype.getMinSend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.ErrMinMaxSend} returns this
 */
proto.chainhop.ErrMinMaxSend.prototype.setMinSend = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string max_send = 3;
 * @return {string}
 */
proto.chainhop.ErrMinMaxSend.prototype.getMaxSend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.ErrMinMaxSend} returns this
 */
proto.chainhop.ErrMinMaxSend.prototype.setMaxSend = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.chainhop.ErrCode = {
  ERROR_CODE_UNDEFINED: 0,
  ERROR_CODE_COMMON: 500,
  ERROR_CODE_NO_ROUTE: 1001,
  ERROR_CODE_NEGATIVE_AMT_OUT: 1002,
  ERROR_CODE_MIN_MAX_SEND: 2001
};

goog.object.extend(exports, proto.chainhop);
