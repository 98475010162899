import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Home from './pages';
import Header from './pages/Header';
import './App.css';
import Footer from './pages/Footer';

function App() {
  const [isMobile, setIsMobile] = useState(false);

  const onResize = useMemo(     
    () =>
      _.throttle(
        () => {
          setIsMobile(document.documentElement.clientWidth <= 768);
          if(document.documentElement.clientWidth <= 750) {
            document.getElementsByTagName('html')[0].style.fontSize = (document.documentElement.clientWidth / 375) * 100 + 'px';
          } else {
            document.getElementsByTagName('html')[0].style.fontSize = (document.documentElement.clientWidth / 1440) * 100 + 'px';
          }
        },
        400
      ),
    []
  );
  useEffect(() => { 
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  useEffect(() => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
  }, [])

  return (
    <div className="App">
      <Header isMobile={isMobile} />
      <Home isMobile={isMobile} />
      <Footer isMobile={isMobile} />
    </div>
  );
}

export default App;
