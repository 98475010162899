import { WebClient } from "../proto/WebServiceClientPb";
import { AnalyticsDataRequest, AnalyticsDataResponse, GetConfigsRequest, GetConfigsResponse } from "../proto/web_pb";

const client = new WebClient(`${process.env.REACT_APP_GRPC_SERVER_URL}`, null, null);

export const getAnalyticsData = (reqParams: AnalyticsDataRequest): Promise<AnalyticsDataResponse> => {
  return client.analyticsData(reqParams, {
    "Access-Control-Allow-Origin": "*",
  })
}

export const getConfigsData = (reqParams: GetConfigsRequest): Promise<GetConfigsResponse> => {
  return client.getConfigs(reqParams, {
    "Access-Control-Allow-Origin": "*",
  })
}
