import { createUseStyles } from "react-jss";

import logoFooter from '../assets/imgs/logo2.png';
import discord from '../assets/imgs/discord.png';
import twitter from '../assets/imgs/twitter.png';
import blog from '../assets/imgs/blog.png';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  footer: {
    background: '#000',
    display: 'flex',
    flexDirection: props => props.isMobile ? 'column' : 'start',
    alignItems: props => props.isMobile ? 'start' : 'center',
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    padding: props => props.isMobile ? '80px 8%' : '100px calc(50% - 650px)',
    boxSizing: 'border-box',
  },
  logo: {
    width: props => props.isMobile ? 100 : 185,
    cursor: 'pointer'
  },
  link: {
    fontSize:  props => props.isMobile ? '0.2rem' : 12,
    fontWeight: 500,
  },
  linkT: {
    marginLeft: props => props.isMobile ? 0 : 24,
    textDecoration: 'none',
    color: '#fff',
    marginTop: props => props.isMobile ? 80 : 0,
  },
  community: {
    position: props => props.isMobile ? 'relative' : 'absolute',
    right: props => props.isMobile ? 0 : 'calc(50% - 650px)',
    width: props => props.isMobile ? '100%' : 'auto',
    display: 'flex',
    marginTop: props => props.isMobile ? 80 : 0,
    justifyContent: 'space-between'
  },
  footerDes: {
    color: 'rgba(255, 255, 255, 0.4)',
    fontSize: props => props.isMobile ? '0.12rem' : 12,
    position: props => props.isMobile ? 'relative' : 'absolute',
    width: props => props.isMobile ? '100%' : 230,
    bottom: props => props.isMobile ? -40 : 40,
    left: props => props.isMobile ? 0 : 'calc(50% - 115px)',
  },
  socialImg: {
    width: props => props.isMobile ? 40 : 24,
    height: props => props.isMobile ? 40 : 24,
    marginLeft:  props => props.isMobile ? 45 : 20,
    cursor: 'pointer',
  }
}));

export type IProps = {
  isMobile: boolean;
};

export default function Footer(props: IProps): JSX.Element {
  const { isMobile = false } = props;
  const classes = useStyles({isMobile});
  return <div className={classes.footer}>
    <img className={classes.logo} src={logoFooter} alt='logo' onClick={() => window.location.reload()} />
    <div className={classes.link}>
      {!isMobile ? <>
        <a className={classes.linkT} href='https://docs.chainhop.exchange/' target="_blank" rel="noreferrer">Docs</a>
        <a className={classes.linkT} href='https://github.com/chainhop-dex/chainhop-contracts/tree/main/audit' target="_blank" rel="noreferrer">Audits</a>
      </> :
      <>
        <div style={{ textAlign: 'left', marginTop: 80 }}><a className={classes.linkT} href='https://docs.chainhop.exchange/' target="_blank" rel="noreferrer">Docs</a></div>
        <div style={{ textAlign: 'left', marginTop: 80 }}><a className={classes.linkT} href='https://github.com/chainhop-dex/chainhop-contracts/tree/main/audit' target="_blank" rel="noreferrer">Audits</a></div>
      
      </>}
    </div>
    <div className={classes.community}>
      <img style={{ marginLeft: 0 }} className={classes.socialImg} src={blog} alt="" onClick={() =>window.open('https://medium.com/@ChainHopDEX')} />
      <img className={classes.socialImg} src={discord} alt="" onClick={() => window.open('https://discord.com/invite/7uuRJbaFue')}/>
      <img className={classes.socialImg} src={twitter} alt="" onClick={() =>window.open('https://twitter.com/ChainHopDex')}/>
    </div>
    <div className={classes.footerDes}>
      © 2023 ChainHop. All rights reserved.  
    </div>
  </div>
}