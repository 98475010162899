import usdc from '../assets/imgs/tokens/usdc.png'; 
import usdt from '../assets/imgs/tokens/usdt.png'; 
import eth from '../assets/imgs/tokens/eth.png'; 
import dai from '../assets/imgs/tokens/dai.png'; 
import busd from '../assets/imgs/tokens/busd.png'; 
import bnb from '../assets/imgs/tokens/bnb.png'; 
import avax from '../assets/imgs/tokens/avax.png'; 
import matic from '../assets/imgs/tokens/matic.png'; 
import btc from '../assets/imgs/tokens/btc.png'; 
import frax from '../assets/imgs/tokens/frax.png'; 
import mim from '../assets/imgs/tokens/mim.png'; 
import op from '../assets/imgs/tokens/op.png'; 
import ftm from '../assets/imgs/tokens/ftm.png'; 
import susd from '../assets/imgs/tokens/susd.png'; 
import gns from '../assets/imgs/tokens/gns.png'; 
// import usx from '../assets/imgs/tokens/usx.png';
import btcb from '../assets/imgs/tokens/bbtc.png'; 
import link from '../assets/imgs/tokens/link.png'; 
import magic from '../assets/imgs/tokens/magic.png'; 

export const displayTokenSymbols = [
  [ usdc, usdt, eth, dai, busd, bnb ],
  [ avax, matic, btc, frax , mim, op ],
  [ ftm, susd, gns, btcb, link, magic],
];
