// source: common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.chainhop.Chain', null, global);
goog.exportSymbol('proto.chainhop.HopHistory', null, global);
goog.exportSymbol('proto.chainhop.Path', null, global);
goog.exportSymbol('proto.chainhop.Rfq', null, global);
goog.exportSymbol('proto.chainhop.RfqPrice', null, global);
goog.exportSymbol('proto.chainhop.Step', null, global);
goog.exportSymbol('proto.chainhop.StepType', null, global);
goog.exportSymbol('proto.chainhop.SwapStatus', null, global);
goog.exportSymbol('proto.chainhop.SwapType', null, global);
goog.exportSymbol('proto.chainhop.SwapTypeCS', null, global);
goog.exportSymbol('proto.chainhop.Token', null, global);
goog.exportSymbol('proto.chainhop.TokenAmount', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.Path = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.Path.repeatedFields_, null);
};
goog.inherits(proto.chainhop.Path, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.Path.displayName = 'proto.chainhop.Path';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.Step = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.Step, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.Step.displayName = 'proto.chainhop.Step';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.TokenAmount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.TokenAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.TokenAmount.displayName = 'proto.chainhop.TokenAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.Chain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.Chain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.Chain.displayName = 'proto.chainhop.Chain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.Rfq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chainhop.Rfq.repeatedFields_, null);
};
goog.inherits(proto.chainhop.Rfq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.Rfq.displayName = 'proto.chainhop.Rfq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.RfqPrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.RfqPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.RfqPrice.displayName = 'proto.chainhop.RfqPrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.Token = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.Token, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.Token.displayName = 'proto.chainhop.Token';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chainhop.HopHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chainhop.HopHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.chainhop.HopHistory.displayName = 'proto.chainhop.HopHistory';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.Path.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.Path.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.Path.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.Path} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Path.toObject = function(includeInstance, msg) {
  var f, obj = {
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    proto.chainhop.Step.toObject, includeInstance),
    amountOut: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amountOutRaw: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amountOutMin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    returnAmount: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bridgeFee: jspb.Message.getFieldWithDefault(msg, 6, ""),
    priceImpact: jspb.Message.getFieldWithDefault(msg, 9, 0),
    etaSeconds: jspb.Message.getFieldWithDefault(msg, 10, 0),
    srcGasFee: jspb.Message.getFieldWithDefault(msg, 11, ""),
    srcGasFeeUsd: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    pathType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    mmId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    rfqPrice: (f = msg.getRfqPrice()) && proto.chainhop.RfqPrice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.Path}
 */
proto.chainhop.Path.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.Path;
  return proto.chainhop.Path.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.Path} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.Path}
 */
proto.chainhop.Path.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chainhop.Step;
      reader.readMessage(value,proto.chainhop.Step.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountOut(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountOutRaw(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountOutMin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnAmount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBridgeFee(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriceImpact(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEtaSeconds(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcGasFee(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSrcGasFeeUsd(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPathType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMmId(value);
      break;
    case 15:
      var value = new proto.chainhop.RfqPrice;
      reader.readMessage(value,proto.chainhop.RfqPrice.deserializeBinaryFromReader);
      msg.setRfqPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.Path.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.Path.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.Path} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Path.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.chainhop.Step.serializeBinaryToWriter
    );
  }
  f = message.getAmountOut();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmountOutRaw();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmountOutMin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReturnAmount();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBridgeFee();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPriceImpact();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getEtaSeconds();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getSrcGasFee();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSrcGasFeeUsd();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getPathType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMmId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRfqPrice();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.chainhop.RfqPrice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Step steps = 1;
 * @return {!Array<!proto.chainhop.Step>}
 */
proto.chainhop.Path.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.chainhop.Step>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chainhop.Step, 1));
};


/**
 * @param {!Array<!proto.chainhop.Step>} value
 * @return {!proto.chainhop.Path} returns this
*/
proto.chainhop.Path.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.chainhop.Step=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chainhop.Step}
 */
proto.chainhop.Path.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.chainhop.Step, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};


/**
 * optional string amount_out = 2;
 * @return {string}
 */
proto.chainhop.Path.prototype.getAmountOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setAmountOut = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string amount_out_raw = 3;
 * @return {string}
 */
proto.chainhop.Path.prototype.getAmountOutRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setAmountOutRaw = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string amount_out_min = 4;
 * @return {string}
 */
proto.chainhop.Path.prototype.getAmountOutMin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setAmountOutMin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string return_amount = 5;
 * @return {string}
 */
proto.chainhop.Path.prototype.getReturnAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setReturnAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string bridge_fee = 6;
 * @return {string}
 */
proto.chainhop.Path.prototype.getBridgeFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setBridgeFee = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 price_impact = 9;
 * @return {number}
 */
proto.chainhop.Path.prototype.getPriceImpact = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setPriceImpact = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 eta_seconds = 10;
 * @return {number}
 */
proto.chainhop.Path.prototype.getEtaSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setEtaSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string src_gas_fee = 11;
 * @return {string}
 */
proto.chainhop.Path.prototype.getSrcGasFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setSrcGasFee = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional double src_gas_fee_usd = 12;
 * @return {number}
 */
proto.chainhop.Path.prototype.getSrcGasFeeUsd = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setSrcGasFeeUsd = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional string path_type = 13;
 * @return {string}
 */
proto.chainhop.Path.prototype.getPathType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setPathType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string mm_id = 14;
 * @return {string}
 */
proto.chainhop.Path.prototype.getMmId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.setMmId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional RfqPrice rfq_price = 15;
 * @return {?proto.chainhop.RfqPrice}
 */
proto.chainhop.Path.prototype.getRfqPrice = function() {
  return /** @type{?proto.chainhop.RfqPrice} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.RfqPrice, 15));
};


/**
 * @param {?proto.chainhop.RfqPrice|undefined} value
 * @return {!proto.chainhop.Path} returns this
*/
proto.chainhop.Path.prototype.setRfqPrice = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.Path} returns this
 */
proto.chainhop.Path.prototype.clearRfqPrice = function() {
  return this.setRfqPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.Path.prototype.hasRfqPrice = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.Step.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.Step.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.Step} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Step.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tokenIn: (f = msg.getTokenIn()) && proto.chainhop.Token.toObject(includeInstance, f),
    tokenOut: (f = msg.getTokenOut()) && proto.chainhop.Token.toObject(includeInstance, f),
    providerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    providerIconUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.Step}
 */
proto.chainhop.Step.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.Step;
  return proto.chainhop.Step.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.Step} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.Step}
 */
proto.chainhop.Step.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.chainhop.StepType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.chainhop.Token;
      reader.readMessage(value,proto.chainhop.Token.deserializeBinaryFromReader);
      msg.setTokenIn(value);
      break;
    case 3:
      var value = new proto.chainhop.Token;
      reader.readMessage(value,proto.chainhop.Token.deserializeBinaryFromReader);
      msg.setTokenOut(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderIconUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.Step.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.Step.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.Step} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Step.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTokenIn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.chainhop.Token.serializeBinaryToWriter
    );
  }
  f = message.getTokenOut();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.chainhop.Token.serializeBinaryToWriter
    );
  }
  f = message.getProviderName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProviderIconUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional StepType type = 1;
 * @return {!proto.chainhop.StepType}
 */
proto.chainhop.Step.prototype.getType = function() {
  return /** @type {!proto.chainhop.StepType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.chainhop.StepType} value
 * @return {!proto.chainhop.Step} returns this
 */
proto.chainhop.Step.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Token token_in = 2;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.Step.prototype.getTokenIn = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.Token, 2));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.Step} returns this
*/
proto.chainhop.Step.prototype.setTokenIn = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.Step} returns this
 */
proto.chainhop.Step.prototype.clearTokenIn = function() {
  return this.setTokenIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.Step.prototype.hasTokenIn = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Token token_out = 3;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.Step.prototype.getTokenOut = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.Token, 3));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.Step} returns this
*/
proto.chainhop.Step.prototype.setTokenOut = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.Step} returns this
 */
proto.chainhop.Step.prototype.clearTokenOut = function() {
  return this.setTokenOut(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.Step.prototype.hasTokenOut = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string provider_name = 4;
 * @return {string}
 */
proto.chainhop.Step.prototype.getProviderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Step} returns this
 */
proto.chainhop.Step.prototype.setProviderName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string provider_icon_url = 5;
 * @return {string}
 */
proto.chainhop.Step.prototype.getProviderIconUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Step} returns this
 */
proto.chainhop.Step.prototype.setProviderIconUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.TokenAmount.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.TokenAmount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.TokenAmount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.TokenAmount.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: (f = msg.getToken()) && proto.chainhop.Token.toObject(includeInstance, f),
    amount: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.TokenAmount}
 */
proto.chainhop.TokenAmount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.TokenAmount;
  return proto.chainhop.TokenAmount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.TokenAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.TokenAmount}
 */
proto.chainhop.TokenAmount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chainhop.Token;
      reader.readMessage(value,proto.chainhop.Token.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.TokenAmount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.TokenAmount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.TokenAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.TokenAmount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chainhop.Token.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Token token = 1;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.TokenAmount.prototype.getToken = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.Token, 1));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.TokenAmount} returns this
*/
proto.chainhop.TokenAmount.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.TokenAmount} returns this
 */
proto.chainhop.TokenAmount.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.TokenAmount.prototype.hasToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string amount = 2;
 * @return {string}
 */
proto.chainhop.TokenAmount.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.TokenAmount} returns this
 */
proto.chainhop.TokenAmount.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.Chain.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.Chain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.Chain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Chain.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    blkdelay: jspb.Message.getFieldWithDefault(msg, 4, 0),
    exploreUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    executionNode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cbridgeMessagebus: jspb.Message.getFieldWithDefault(msg, 8, ""),
    rfqInfo: (f = msg.getRfqInfo()) && proto.chainhop.Rfq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.Chain}
 */
proto.chainhop.Chain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.Chain;
  return proto.chainhop.Chain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.Chain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.Chain}
 */
proto.chainhop.Chain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBlkdelay(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExploreUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionNode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCbridgeMessagebus(value);
      break;
    case 9:
      var value = new proto.chainhop.Rfq;
      reader.readMessage(value,proto.chainhop.Rfq.deserializeBinaryFromReader);
      msg.setRfqInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.Chain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.Chain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.Chain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Chain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBlkdelay();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getExploreUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExecutionNode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCbridgeMessagebus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRfqInfo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.chainhop.Rfq.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.chainhop.Chain.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.Chain} returns this
 */
proto.chainhop.Chain.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.chainhop.Chain.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Chain} returns this
 */
proto.chainhop.Chain.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.chainhop.Chain.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Chain} returns this
 */
proto.chainhop.Chain.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 blkdelay = 4;
 * @return {number}
 */
proto.chainhop.Chain.prototype.getBlkdelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.Chain} returns this
 */
proto.chainhop.Chain.prototype.setBlkdelay = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string explore_url = 6;
 * @return {string}
 */
proto.chainhop.Chain.prototype.getExploreUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Chain} returns this
 */
proto.chainhop.Chain.prototype.setExploreUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string execution_node = 7;
 * @return {string}
 */
proto.chainhop.Chain.prototype.getExecutionNode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Chain} returns this
 */
proto.chainhop.Chain.prototype.setExecutionNode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string cbridge_messagebus = 8;
 * @return {string}
 */
proto.chainhop.Chain.prototype.getCbridgeMessagebus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Chain} returns this
 */
proto.chainhop.Chain.prototype.setCbridgeMessagebus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Rfq rfq_info = 9;
 * @return {?proto.chainhop.Rfq}
 */
proto.chainhop.Chain.prototype.getRfqInfo = function() {
  return /** @type{?proto.chainhop.Rfq} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.Rfq, 9));
};


/**
 * @param {?proto.chainhop.Rfq|undefined} value
 * @return {!proto.chainhop.Chain} returns this
*/
proto.chainhop.Chain.prototype.setRfqInfo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.Chain} returns this
 */
proto.chainhop.Chain.prototype.clearRfqInfo = function() {
  return this.setRfqInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.Chain.prototype.hasRfqInfo = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chainhop.Rfq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.Rfq.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.Rfq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.Rfq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Rfq.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokensList: jspb.Message.toObjectList(msg.getTokensList(),
    proto.chainhop.Token.toObject, includeInstance),
    contractAddr: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.Rfq}
 */
proto.chainhop.Rfq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.Rfq;
  return proto.chainhop.Rfq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.Rfq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.Rfq}
 */
proto.chainhop.Rfq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chainhop.Token;
      reader.readMessage(value,proto.chainhop.Token.deserializeBinaryFromReader);
      msg.addTokens(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.Rfq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.Rfq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.Rfq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Rfq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokensList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.chainhop.Token.serializeBinaryToWriter
    );
  }
  f = message.getContractAddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Token tokens = 1;
 * @return {!Array<!proto.chainhop.Token>}
 */
proto.chainhop.Rfq.prototype.getTokensList = function() {
  return /** @type{!Array<!proto.chainhop.Token>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chainhop.Token, 1));
};


/**
 * @param {!Array<!proto.chainhop.Token>} value
 * @return {!proto.chainhop.Rfq} returns this
*/
proto.chainhop.Rfq.prototype.setTokensList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.chainhop.Token=} opt_value
 * @param {number=} opt_index
 * @return {!proto.chainhop.Token}
 */
proto.chainhop.Rfq.prototype.addTokens = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.chainhop.Token, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.chainhop.Rfq} returns this
 */
proto.chainhop.Rfq.prototype.clearTokensList = function() {
  return this.setTokensList([]);
};


/**
 * optional string contract_addr = 2;
 * @return {string}
 */
proto.chainhop.Rfq.prototype.getContractAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Rfq} returns this
 */
proto.chainhop.Rfq.prototype.setContractAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.RfqPrice.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.RfqPrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.RfqPrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqPrice.toObject = function(includeInstance, msg) {
  var f, obj = {
    srcToken: (f = msg.getSrcToken()) && proto.chainhop.Token.toObject(includeInstance, f),
    srcAmount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dstToken: (f = msg.getDstToken()) && proto.chainhop.Token.toObject(includeInstance, f),
    srcReleaseAmount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dstAmount: jspb.Message.getFieldWithDefault(msg, 5, ""),
    validThru: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mmAddr: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sig: jspb.Message.getFieldWithDefault(msg, 8, ""),
    srcDeadlinePeriod: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dstDeadlinePeriod: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.RfqPrice}
 */
proto.chainhop.RfqPrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.RfqPrice;
  return proto.chainhop.RfqPrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.RfqPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.RfqPrice}
 */
proto.chainhop.RfqPrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chainhop.Token;
      reader.readMessage(value,proto.chainhop.Token.deserializeBinaryFromReader);
      msg.setSrcToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcAmount(value);
      break;
    case 3:
      var value = new proto.chainhop.Token;
      reader.readMessage(value,proto.chainhop.Token.deserializeBinaryFromReader);
      msg.setDstToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcReleaseAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setValidThru(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMmAddr(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSig(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSrcDeadlinePeriod(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDstDeadlinePeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.RfqPrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.RfqPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.RfqPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.RfqPrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrcToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chainhop.Token.serializeBinaryToWriter
    );
  }
  f = message.getSrcAmount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDstToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.chainhop.Token.serializeBinaryToWriter
    );
  }
  f = message.getSrcReleaseAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDstAmount();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getValidThru();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getMmAddr();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSig();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSrcDeadlinePeriod();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDstDeadlinePeriod();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional Token src_token = 1;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.RfqPrice.prototype.getSrcToken = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.Token, 1));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.RfqPrice} returns this
*/
proto.chainhop.RfqPrice.prototype.setSrcToken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.clearSrcToken = function() {
  return this.setSrcToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqPrice.prototype.hasSrcToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string src_amount = 2;
 * @return {string}
 */
proto.chainhop.RfqPrice.prototype.getSrcAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.setSrcAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Token dst_token = 3;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.RfqPrice.prototype.getDstToken = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.Token, 3));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.RfqPrice} returns this
*/
proto.chainhop.RfqPrice.prototype.setDstToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.clearDstToken = function() {
  return this.setDstToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.RfqPrice.prototype.hasDstToken = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string src_release_amount = 4;
 * @return {string}
 */
proto.chainhop.RfqPrice.prototype.getSrcReleaseAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.setSrcReleaseAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dst_amount = 5;
 * @return {string}
 */
proto.chainhop.RfqPrice.prototype.getDstAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.setDstAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint64 valid_thru = 6;
 * @return {number}
 */
proto.chainhop.RfqPrice.prototype.getValidThru = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.setValidThru = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string mm_addr = 7;
 * @return {string}
 */
proto.chainhop.RfqPrice.prototype.getMmAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.setMmAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sig = 8;
 * @return {string}
 */
proto.chainhop.RfqPrice.prototype.getSig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.setSig = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 src_deadline_period = 9;
 * @return {number}
 */
proto.chainhop.RfqPrice.prototype.getSrcDeadlinePeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.setSrcDeadlinePeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 dst_deadline_period = 10;
 * @return {number}
 */
proto.chainhop.RfqPrice.prototype.getDstDeadlinePeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.RfqPrice} returns this
 */
proto.chainhop.RfqPrice.prototype.setDstDeadlinePeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.Token.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.Token.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.Token} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Token.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    decimals: jspb.Message.getFieldWithDefault(msg, 4, 0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    logoUri: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.Token}
 */
proto.chainhop.Token.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.Token;
  return proto.chainhop.Token.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.Token} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.Token}
 */
proto.chainhop.Token.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDecimals(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.Token.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.Token.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.Token} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.Token.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDecimals();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLogoUri();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.chainhop.Token.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.Token} returns this
 */
proto.chainhop.Token.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.chainhop.Token.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Token} returns this
 */
proto.chainhop.Token.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.chainhop.Token.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Token} returns this
 */
proto.chainhop.Token.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 decimals = 4;
 * @return {number}
 */
proto.chainhop.Token.prototype.getDecimals = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.Token} returns this
 */
proto.chainhop.Token.prototype.setDecimals = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.chainhop.Token.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Token} returns this
 */
proto.chainhop.Token.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string logo_uri = 6;
 * @return {string}
 */
proto.chainhop.Token.prototype.getLogoUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.Token} returns this
 */
proto.chainhop.Token.prototype.setLogoUri = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chainhop.HopHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.chainhop.HopHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chainhop.HopHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.HopHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    swapId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amountIn: (f = msg.getAmountIn()) && proto.chainhop.TokenAmount.toObject(includeInstance, f),
    amountOut: (f = msg.getAmountOut()) && proto.chainhop.TokenAmount.toObject(includeInstance, f),
    createTs: jspb.Message.getFieldWithDefault(msg, 4, 0),
    srcBlockTxLink: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dstBlockTxLink: jspb.Message.getFieldWithDefault(msg, 6, ""),
    swapStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cbrTransferId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    refundedMidTokenOnDst: (f = msg.getRefundedMidTokenOnDst()) && proto.chainhop.Token.toObject(includeInstance, f),
    refundedTokenAmtOnDst: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bridgeType: jspb.Message.getFieldWithDefault(msg, 11, ""),
    swapType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    updateTs: jspb.Message.getFieldWithDefault(msg, 13, 0),
    dstDeadline: jspb.Message.getFieldWithDefault(msg, 14, 0),
    swapTypeCs: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chainhop.HopHistory}
 */
proto.chainhop.HopHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chainhop.HopHistory;
  return proto.chainhop.HopHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chainhop.HopHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chainhop.HopHistory}
 */
proto.chainhop.HopHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapId(value);
      break;
    case 2:
      var value = new proto.chainhop.TokenAmount;
      reader.readMessage(value,proto.chainhop.TokenAmount.deserializeBinaryFromReader);
      msg.setAmountIn(value);
      break;
    case 3:
      var value = new proto.chainhop.TokenAmount;
      reader.readMessage(value,proto.chainhop.TokenAmount.deserializeBinaryFromReader);
      msg.setAmountOut(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCreateTs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrcBlockTxLink(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDstBlockTxLink(value);
      break;
    case 7:
      var value = /** @type {!proto.chainhop.SwapStatus} */ (reader.readEnum());
      msg.setSwapStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCbrTransferId(value);
      break;
    case 9:
      var value = new proto.chainhop.Token;
      reader.readMessage(value,proto.chainhop.Token.deserializeBinaryFromReader);
      msg.setRefundedMidTokenOnDst(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefundedTokenAmtOnDst(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBridgeType(value);
      break;
    case 12:
      var value = /** @type {!proto.chainhop.SwapType} */ (reader.readEnum());
      msg.setSwapType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUpdateTs(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDstDeadline(value);
      break;
    case 15:
      var value = /** @type {!proto.chainhop.SwapTypeCS} */ (reader.readEnum());
      msg.setSwapTypeCs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chainhop.HopHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.chainhop.HopHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.chainhop.HopHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.chainhop.HopHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwapId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmountIn();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.chainhop.TokenAmount.serializeBinaryToWriter
    );
  }
  f = message.getAmountOut();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.chainhop.TokenAmount.serializeBinaryToWriter
    );
  }
  f = message.getCreateTs();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getSrcBlockTxLink();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDstBlockTxLink();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSwapStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getCbrTransferId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRefundedMidTokenOnDst();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.chainhop.Token.serializeBinaryToWriter
    );
  }
  f = message.getRefundedTokenAmtOnDst();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBridgeType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSwapType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getUpdateTs();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getDstDeadline();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getSwapTypeCs();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * optional string swap_id = 1;
 * @return {string}
 */
proto.chainhop.HopHistory.prototype.getSwapId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setSwapId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TokenAmount amount_in = 2;
 * @return {?proto.chainhop.TokenAmount}
 */
proto.chainhop.HopHistory.prototype.getAmountIn = function() {
  return /** @type{?proto.chainhop.TokenAmount} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.TokenAmount, 2));
};


/**
 * @param {?proto.chainhop.TokenAmount|undefined} value
 * @return {!proto.chainhop.HopHistory} returns this
*/
proto.chainhop.HopHistory.prototype.setAmountIn = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.clearAmountIn = function() {
  return this.setAmountIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.HopHistory.prototype.hasAmountIn = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TokenAmount amount_out = 3;
 * @return {?proto.chainhop.TokenAmount}
 */
proto.chainhop.HopHistory.prototype.getAmountOut = function() {
  return /** @type{?proto.chainhop.TokenAmount} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.TokenAmount, 3));
};


/**
 * @param {?proto.chainhop.TokenAmount|undefined} value
 * @return {!proto.chainhop.HopHistory} returns this
*/
proto.chainhop.HopHistory.prototype.setAmountOut = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.clearAmountOut = function() {
  return this.setAmountOut(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.HopHistory.prototype.hasAmountOut = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 create_ts = 4;
 * @return {number}
 */
proto.chainhop.HopHistory.prototype.getCreateTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setCreateTs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string src_block_tx_link = 5;
 * @return {string}
 */
proto.chainhop.HopHistory.prototype.getSrcBlockTxLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setSrcBlockTxLink = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dst_block_tx_link = 6;
 * @return {string}
 */
proto.chainhop.HopHistory.prototype.getDstBlockTxLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setDstBlockTxLink = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional SwapStatus swap_status = 7;
 * @return {!proto.chainhop.SwapStatus}
 */
proto.chainhop.HopHistory.prototype.getSwapStatus = function() {
  return /** @type {!proto.chainhop.SwapStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.chainhop.SwapStatus} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setSwapStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string cbr_transfer_id = 8;
 * @return {string}
 */
proto.chainhop.HopHistory.prototype.getCbrTransferId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setCbrTransferId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional Token refunded_mid_token_on_dst = 9;
 * @return {?proto.chainhop.Token}
 */
proto.chainhop.HopHistory.prototype.getRefundedMidTokenOnDst = function() {
  return /** @type{?proto.chainhop.Token} */ (
    jspb.Message.getWrapperField(this, proto.chainhop.Token, 9));
};


/**
 * @param {?proto.chainhop.Token|undefined} value
 * @return {!proto.chainhop.HopHistory} returns this
*/
proto.chainhop.HopHistory.prototype.setRefundedMidTokenOnDst = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.clearRefundedMidTokenOnDst = function() {
  return this.setRefundedMidTokenOnDst(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.chainhop.HopHistory.prototype.hasRefundedMidTokenOnDst = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string refunded_token_amt_on_dst = 10;
 * @return {string}
 */
proto.chainhop.HopHistory.prototype.getRefundedTokenAmtOnDst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setRefundedTokenAmtOnDst = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string bridge_type = 11;
 * @return {string}
 */
proto.chainhop.HopHistory.prototype.getBridgeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setBridgeType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional SwapType swap_type = 12;
 * @return {!proto.chainhop.SwapType}
 */
proto.chainhop.HopHistory.prototype.getSwapType = function() {
  return /** @type {!proto.chainhop.SwapType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.chainhop.SwapType} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setSwapType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional uint64 update_ts = 13;
 * @return {number}
 */
proto.chainhop.HopHistory.prototype.getUpdateTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setUpdateTs = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint64 dst_deadline = 14;
 * @return {number}
 */
proto.chainhop.HopHistory.prototype.getDstDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setDstDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional SwapTypeCS swap_type_cs = 15;
 * @return {!proto.chainhop.SwapTypeCS}
 */
proto.chainhop.HopHistory.prototype.getSwapTypeCs = function() {
  return /** @type {!proto.chainhop.SwapTypeCS} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.chainhop.SwapTypeCS} value
 * @return {!proto.chainhop.HopHistory} returns this
 */
proto.chainhop.HopHistory.prototype.setSwapTypeCs = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * @enum {number}
 */
proto.chainhop.SwapStatus = {
  SS_UNKNOWN: 0,
  SS_PENDING: 1,
  SS_SRC_SUCCESS: 2,
  SS_SRC_FAILED: 3,
  SS_DST_COMPLETED: 4,
  SS_DST_REFUNDED: 5,
  SS_SRC_REFUNDED: 6,
  SS_RFQ_PENDING: 7,
  SS_RFQ_SUBMITTING: 8,
  SS_RFQ_NO_SUBMIT: 9
};

/**
 * @enum {number}
 */
proto.chainhop.SwapType = {
  ST_UNKNOWN: 0,
  ST_CROSS_CHAIN: 1,
  ST_DIRECT: 2,
  ST_CROSS_CHAIN_TO_USR: 3,
  ST_2HOPS: 4,
  ST_RFQ: 5
};

/**
 * @enum {number}
 */
proto.chainhop.SwapTypeCS = {
  STC_UNKNOWN: 0,
  STC_CROSS_CHAIN: 1,
  STC_DIRECT: 2,
  STC_CROSS_CHAIN_TO_USR: 3,
  STC_2HOPS: 4,
  STC_RFQ: 5,
  STC_DIRECT_BRIDGE: 40
};

/**
 * @enum {number}
 */
proto.chainhop.StepType = {
  STEP_UNKNOWN: 0,
  STEP_DEX: 1,
  STEP_BRIDGE: 2
};

goog.object.extend(exports, proto.chainhop);
