import { createUseStyles } from "react-jss";
import cla from "classnames";
import 'animate.css';
import ScrollLoad from "../components/ScrollLoad";
import { 
  AnalyticsDataRequest, 
  AnalyticsDataResponse,
} from "../proto/web_pb";


// import rightPoint from '../assets/imgs/rightPoint.png';
import { useEffect, useState } from "react";
import { getAnalyticsData } from "../redux/client";

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  section: {
    marginTop: props => props.isMobile ? 120 : 300,
  },
  top: {
    padding: props => props.isMobile ? '160px 8%' : '163px calc(50% - 650px)',
    display: 'flex',
    flexDirection: props => props.isMobile ? 'column' : 'start',
    height: props => props.isMobile ? 'auto' : 500,
    backgroundColor: '#000',
  },
  text: {
    fontSize: props => props.isMobile ? '0.48rem' : 72,
    color: "white",
    fontWeight: 700,
    lineHeight: props => props.isMobile ? '0.57rem' : '96px',
    textAlign: 'left'
  },
  left: {
    width: props => props.isMobile ? '100%' : 640,
  },
  right: {
    marginLeft: props => props.isMobile ? 0 : 40,
  },
  num: {
    fontSize: props => props.isMobile ? '0.64rem' : 96,
    color: "white",
    fontWeight: 700,
    lineHeight: props => props.isMobile ? '0.76rem' : '115px',
    textAlign: 'left',
    marginTop: props => props.isMobile ? 48 : 40,
  },
  numTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  numDes: {
    color: '#fff',
    fontSize: props => props.isMobile ? '0.2rem' : 20,
    fontWeight: 300,
    lineHeight: '24px',
    textIndent: 8,
  },
  middle: {
    width: props => props.isMobile ? 'calc(100% - 100px)' : 1300,
    border: '2px solid #000',
    marginTop: 160,
    margin: '0 auto'
  },
  middleInner: {
    padding: props => props.isMobile ? '68px 0.2rem' : '100px 40px',
  },
  middleTitle: {
    fontWeight: 700,
    fontSize: props => props.isMobile ? '0.45rem' : 96,
    lineHeight: props => props.isMobile ? '0.57rem' : '115px',
  },
  middleDes: {
    marginTop: 48,
    fontSize: props => props.isMobile ? '0.2rem' : 24,
    lineHeight: props => props.isMobile ? '0.24rem' : '28px',
    width: props => props.isMobile ? '100%' : 1100,
  },
  btnWrapper: {
    width: props => props.isMobile ? '100%' : 484,
    display: 'flex',
    flexDirection: props => props.isMobile ? 'column' : 'start',
    justifyContent: 'space-around',
    margin: '0 auto'
  },
  btn: {
    cursor: 'pointer',
    height: props => props.isMobile ? 44 : 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: props => props.isMobile ? 16 : 24,
    borderRadius: 0,
    width: props => props.isMobile ? '100%' : 'auto',
    marginTop: 40,
    fontWeight: 700,
    padding: props => props.isMobile ? '0' : '16px 24px',
  },

  integrate: {
    backgroundColor: '#86C2FF',
    color: '#000',

    '&:hover': {
      backgroundColor: '#86C2FF !important',
      color: '#000 !important'
    }
  },
  contact: {
    backgroundColor: '#000',
    color: '#fff',
    marginTop: props => props.isMobile ? 20 : 40,

    '&:hover': {
      backgroundColor: '#000 !important',
      color: '#fff !important'
    }
  },
  divider: {
    height: 10,
    border: 'none',
    borderBottom: '2px solid #000',
  },
  bottom: {
    margin: '0 auto',
    backgroundColor: '#E7F3FF',
    marginTop: 160,
    // padding: props => props.isMobile ? '80px 30px' : '138px 113px',
    padding: props => props.isMobile ? '80px 8%' : '138px calc(50% - 650px)',
  },
  bottomTitle: {
    fontWeight: 600,
    fontSize: props => props.isMobile ? '0.32rem' : 64,
    lineHeight:  props => props.isMobile ? '38px' : '58px',
    margin: '0 auto'
  },
  read: {
    backgroundColor: '#000',
    color: '#fff',
    width: 268,
    margin: '0 auto',
    marginTop: 30,

    '&:hover': {
      backgroundColor: '#000 !important',
      color: '#fff !important',
    }
  },
  rightPoint: {
    width: 20,
    marginLeft: 12,
  },
  pointMouseIn: {
    transition: 'all 0.6s',
    transform: 'scale(1.3)',
  },
  pointMouseOut: {
    transition: 'all 0.6s',
    transform: 'scale(1)',
  }
}))

export type IProps = {
  isMobile: boolean;
};
export default function FifthSection(props: IProps): JSX.Element {
  const { isMobile = false } = props;
  const classes = useStyles({isMobile});
  const [analyticsData, setAnalyticsData] = useState<AnalyticsDataResponse.AsObject>();
  // const animateCls = 'animate__animated animate__fadeInUp animate__slower';
  const animateHeaderCls = 'animate__animated animate__fadeInUp';
  const animateFadeInCls = 'animate__animated animate__fadeIn animate__slower';

  useEffect(() => {
    const analyticsDataRequest = new AnalyticsDataRequest();
    getAnalyticsData(analyticsDataRequest).then(res => {
      console.log('res:', res.toObject());
      setAnalyticsData(res.toObject());
    });
    // const getConfigsRequest = new GetConfigsRequest();
    // getConfigsData(getConfigsRequest).then(res => {
    //   console.log('res2:', res.toObject());
    // });
  }, [])

  return (
    <div className={classes.section}>
      <ScrollLoad>
          <div className={cla(classes.top, animateHeaderCls)}>
            <div className={cla(classes.left, animateFadeInCls)}>
              <h3 className={classes.text} style={{ margin: 0 }}>
                Endorsed by 
              </h3>
              <h3 className={classes.text} style={{ margin: 0 }}>
                developers
              </h3>
              <h3 className={classes.text} style={{ margin: 0, marginTop: isMobile ? 0 : 40 }}>
              Trusted by
              </h3>
              <h3 className={classes.text}  style={{ margin: 0 }}>
                users
              </h3>
            </div>
            <div className={cla(classes.right, animateFadeInCls)}>
              <div className={classes.num} style={{ marginTop: isMobile ? 48 : 0 }}>
                <div className={classes.numTitle}>{((analyticsData?.volumeTotal|| 0) / 1000000).toFixed() || 0}M <span style={{ color: '#9C9AFF', fontSize: 48 }}>+</span></div>
                <div className={classes.numDes}>Volume swapped ($) </div>
              </div>
              <div className={classes.num}>
                <div className={classes.numTitle}>{((analyticsData?.cntSwapTotal || 0) / 1000).toFixed() || 0}K <span style={{ color: '#90C8FF', fontSize: 48 }}>+</span></div>
                <div className={classes.numDes}>Transactions</div>
              </div>
              <div className={classes.num}>
                <div className={classes.numTitle}>{((analyticsData?.cntUniqueAddr || 0) / 1000).toFixed() || 0}K<span style={{ color: '#FFFFFF', fontSize: 48 }}>+</span></div>
                <div className={classes.numDes}>Unique addresses</div>
              </div>
            </div>
          </div>
      </ScrollLoad>
    </div>
  )
}