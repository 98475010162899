import { useState } from "react";
import { createUseStyles } from "react-jss";
import cla from "classnames";
import { Button, Divider, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'

import logo from '../assets/imgs/logo.png';
import discord from '../assets/imgs/discord2.png';
import twitter from '../assets/imgs/twitter2.png';
import blog from '../assets/imgs/blog2.png';



const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  header: {
    zIndex: 999,
    height: props => props.isMobile ? '0.8rem' : 60,
    marginTop: props => props.isMobile ? 0 : 50,
    position: 'fixed',
    top: 0,
    width: '100%',
    margin: '0 auto',
    padding: props => props.isMobile ? 0 : '0 calc(50% - 650px)',
    paddingLeft: props => props.isMobile ? 20 : '0 calc(50% - 650px)',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: props => props.isMobile ? '#FDFDFD' : '#fff',
  },
  logo: {
    width: props => props.isMobile ? 120 : 185,
    cursor: 'pointer'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bolder',
  },
  link: {
    fontSize: 16,
    fontWeight: 500,
    position: 'fixed',
    marginLeft: 374,
    right: 'calc(50% - 650px + 392px)',
  },
  linkT: {
    marginLeft: 32,
    textDecoration: 'none',
    color: '#000',

    '&:hover': {
      color: '#5CADFF',
    }
  },
  overlayCommunity: {
    position: 'fixed !important',
    top: '100px !important',

    '& ul:first-child': {
      padding: '32px 40px',
    },
    '& .ant-dropdown-menu-item:hover': {
      backgroundColor: '#fff !important'
    }
  },
  btnWrapper: {
    display: 'flex',
    position: 'fixed',
    right: 'calc(50% - 650px)',
  },
  btn: {
    cursor: 'pointer',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 160,
    fontSize: 16,
    borderRadius: 0,
    fontWeight: 700,
  },
  contact: {
    backgroundColor: '#fff',
    color: '#000',
    border: '3px solid #000',

    '&:hover': {
      backgroundColor: '#fff !important',
      color: '#5CADFF !important',
      borderColor: '#5CADFF !important', 

    }
  },
  launch: {
    marginLeft: 32,
    backgroundColor: '#000',

    '&:hover': {
      backgroundColor: '#86C2FF !important',
      color: '#fff !important',
    }
  },
  mobile_header: {
    height: '0.8rem',
    width: '100%',
    display: 'flex',
  },
  menuIcon: {
    position: 'absolute',
    right: 32,
    color: '#232323',
    fontSize: 18,
  },
  menuModal: {
    // height: 'calc(100vh - 78px)',
    height: '100vh',
    width: '100%',
    maxWidth: '100%',
    position: 'absolute',
    backgroundColor: '#fff',
    top: 78,
    left: 0,
  },
  mlink: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textIndent: 40,
  },
  mlinkT: {
    fontSize: '0.2rem',
    fontWeight: '510',
    textDecoration: 'none',
    color: '#000',
    textAlign: 'left',
    padding: '15px 0',
  },
  mCommunity: {
    width: '100%',
    display: 'flex',
    position: 'absolute',
    left: 0,
    bottom: 160,
  },
  mSocialImg: {
    width: 40,
    marginLeft: 48,
  },
  socialImgWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  socialImg: {
    width: 24,
    height: 24,
    marginRight: 20,
  },
  clink: {
    textDecoration: 'none',
    color: '#000',
    fontWeight: 700,
    fontSize: 16,
    '&:hover': {
      color: '#5CADFF',
    }
  }
}));

export type IProps = {
  isMobile: boolean;
};

export default function Header(props: IProps): JSX.Element {
  const { isMobile } = props;
  const classes = useStyles({isMobile});
  const [ menuVisible, setMenuVisible ] = useState(false);
  
  const animateCls = 'animate__animated animate__slideInUp';

  const items: MenuProps['items'] = [
    {
      label: <div style={{ marginTop: 0 }} className={classes.socialImgWrapper}>
        <img className={classes.socialImg} src={discord} alt="" onClick={() => window.open('https://discord.com/invite/7uuRJbaFue')}/>
        <a className={classes.clink} href='https://discord.com/invite/7uuRJbaFue' target='_blank' rel="noreferrer">Discord</a>
      </div>,
      key: '0',
    },
    {
      label: <div className={classes.socialImgWrapper}>
        <img className={classes.socialImg} src={twitter} alt="" onClick={() => window.open('https://twitter.com/ChainHopDex')}/>
        <a className={classes.clink} href='https://twitter.com/ChainHopDex' target='_blank' rel="noreferrer">Twitter</a>
      </div>
      ,
      key: '1',
    },
    {
      label: <div className={classes.socialImgWrapper}>
        <img className={classes.socialImg} src={blog} alt="" onClick={() => window.open('https://medium.com/@ChainHopDEX')}/>
        <a className={classes.clink} href='https://medium.com/@ChainHopDEX' target='_blank' rel="noreferrer">Blog</a>
      </div>,
      key: '2',
    },
  ];

  return !props.isMobile ? <div style={{ height: 110, width: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, zIndex: 999 }}><div className={classes.header}>
    <img className={classes.logo} src={logo} alt='logo' onClick={() => window.location.reload()}/>
    <div className={classes.link}>
      <a className={classes.linkT} href='https://docs.chainhop.exchange/' target="_blank" rel="noreferrer">Docs</a>
      <a className={classes.linkT} href='https://github.com/chainhop-dex/chainhop-contracts/tree/main/audit' target="_blank" rel="noreferrer">Audits</a>
      <Dropdown menu={{items}} overlayClassName={classes.overlayCommunity} placement="bottom">
        <a className={cla(classes.linkT, classes.communityLinkT)} href='http://' onClick={(e) => e.preventDefault()}>Community</a>
      </Dropdown>
    </div>
    <div className={classes.btnWrapper}>
      <Button type="primary" className={cla(classes.btn, classes.contact)} onClick={() => window.open('https://form.typeform.com/to/vp9YO1I5?typeform-source=www.google.com.hk')}> 
        CONTACT US
      </Button>
      <Button type="primary" className={cla(classes.btn, classes.launch)} onClick={() => window.open('https://app.chainhop.exchange/')}>
        LAUNCH APP
      </Button>
    </div>
  </div></div> : <div className={classes.header}>
    <img className={classes.logo} src={logo} alt='logo' />
    {!menuVisible ? <MenuOutlined className={classes.menuIcon} onClick = {() => setMenuVisible(true)} /> :
    <CloseOutlined className={classes.menuIcon} onClick = {() => setMenuVisible(false)} />}
    {menuVisible && <div className={cla(classes.menuModal, animateCls)}>
      <div className={classes.mlink}>
        <a className={classes.mlinkT} href='https://docs.chainhop.exchange/' target="_blank" rel="noreferrer">Docs</a>
        <Divider />
        <a className={classes.mlinkT} href='https://github.com/chainhop-dex/chainhop-contracts/tree/main/audit' target="_blank" rel="noreferrer">Audits</a>
        <Divider />
        <a className={classes.mlinkT} href='https://form.typeform.com/to/vp9YO1I5' target="_blank" rel="noreferrer">Contact us</a>
        <Divider />
        <a className={classes.mlinkT} href='https://app.chainhop.exchange/' target="_blank" rel="noreferrer">Launch App</a>
        <Divider />
      </div>
      <div className={classes.mCommunity}> 
        <img className={classes.mSocialImg} src={discord} alt="" onClick={() => window.open('https://discord.com/invite/7uuRJbaFue')}/>
        <img className={classes.mSocialImg} src={twitter} alt="" onClick={() => window.open('https://twitter.com/ChainHopDex')}/>
        <img className={classes.mSocialImg} src={blog} alt="" onClick={() => window.open('https://medium.com/@ChainHopDEX')}/>
      </div>
    </div>}
  </div>
}