import FifthSection from "./FifthSection";
import FirstSection from "./FirstSection";
import ForthSection from "./ForthSecond";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import SixSection from "./SixSection";

export type IProps = {
  isMobile: boolean;
};

const Home = (props: IProps) => {
  const { isMobile } = props;

  return <div>
    <FirstSection isMobile={isMobile} />
    <SecondSection isMobile={isMobile} />
    <ThirdSection isMobile={isMobile} />
    <ForthSection isMobile={isMobile} />
    <FifthSection isMobile={isMobile} />
    <SixSection isMobile={isMobile} />
  </div>
}

export default Home;