import { createUseStyles } from "react-jss";
import cla from "classnames";
import 'animate.css';
import ScrollLoad from "../components/ScrollLoad";
import { displayTokenSymbols } from "../constants/chain_token_config";

import frame1 from '../assets/imgs/frame1.png';
import frame2 from '../assets/imgs/frame2.png';
import frame3 from '../assets/imgs/frame3.png';
import frame4 from '../assets/imgs/frame4.png';
import frame5 from '../assets/imgs/frame5.png';
import frame6 from '../assets/imgs/frame6.png';
import frame7 from '../assets/imgs/frame7.png';

import mframe1 from '../assets/imgs/mframe1.png';
import mframe2 from '../assets/imgs/mframe2.png';
import mframe3 from '../assets/imgs/mframe3.png';
import mframe4 from '../assets/imgs/mframe4.png';
import mframe5 from '../assets/imgs/mframe5.png';
import mframe6 from '../assets/imgs/mframe6.png';
import mframe7 from '../assets/imgs/mframe7.png';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  section: {
    width: props => props.isMobile ? '3.15rem' : 1300,
    display: 'flex',
    margin: '0 auto',
    marginTop: props => props.isMobile ? 150 : 300,
    flexDirection: props => props.isMobile ? 'column' : 'start',
    position: 'relative',
  },
  header: {
    width: props => props.isMobile ? '100%' : 536,
    fontSize: props => props.isMobile ? '0.48rem' : 72,
    fontWeight: 700,
    lineHeight: props => props.isMobile ? '68px' : '112px',
    marginRight: 48,
    marginTop: 0,
    textAlign: 'left',
    position: 'sticky',
    top: 100,
    left: 0,
  },
  desWrapper: {
    width: props => props.isMobile ? '100%' : 583,
    marginTop: props => props.isMobile ? 56 : 0,
    textAlign: 'left',
    marginLeft: props => props.isMobile ? 0 : 40,
  },
  desTitle: {
    width: '100%',
    fontSize: props => props.isMobile ? '0.16rem' : 24,
    fontWeight: 600,
    marginTop: 55,
    color: '#555',
  },
  frameImg: {
    display: 'block',
    marginTop: props => props.isMobile ? 35 : 24,
    height: props => props.isMobile ? 40 : 80,
  },
  symbolsContainer: {
    display: 'flex',
    width: props => props.isMobile ? '95%' : 480,
    marginTop: props => props.isMobile ? 35 : 24,
  },
  displayToken: {
    width: props => props.isMobile ? 30 : 40,
    marginRight: props => props.isMobile ? 20 : 50,
  }
}))

export type IProps = {
  isMobile: boolean;
};

export default function ForthSection(props: IProps): JSX.Element {
  const { isMobile } = props;
  const classes = useStyles({isMobile});
  const animateCls = 'animate__animated animate__fadeInUp animate__slower';
  const animateHeaderCls = 'animate__animated animate__fadeIn animate__slower';

  return (
    <div className={cla(classes.section, animateCls)}>
      <ScrollLoad scrollHeight='200px'>
        <div className={classes.stickyContainer}>
          <h2 className={cla(classes.header, animateHeaderCls)}>
            Aggregating deep liquidity on leading protocols
          </h2>
        </div>
      </ScrollLoad>
      <div className={classes.desWrapper}>
        <ScrollLoad scrollHeight='200px'>
          <div className={animateCls}>
            <div className={cla(classes.desTitle)} style={{ marginTop: 0 }}>Supported by the best cross-chain bridges</div>
            <img className={cla(classes.frameImg)} src={isMobile ? mframe1 : frame1} alt=''/>
            <img className={cla(classes.frameImg)} src={isMobile ? mframe2 : frame2} alt=''/>
          </div>
        </ScrollLoad>
        <ScrollLoad scrollHeight='200px'>
          <div className={animateCls}>
            <div className={cla(classes.desTitle)}>Access all of the leading DEXes and their liquidity</div>  
            <img className={cla(classes.frameImg)} src={isMobile ? mframe3 : frame3} alt=''/>         
            <img className={cla(classes.frameImg)} src={isMobile ? mframe4 : frame4} alt=''/>      
            <img className={cla(classes.frameImg)} src={isMobile ? mframe5 : frame5} alt=''/>
          </div>
        </ScrollLoad>
        <ScrollLoad scrollHeight='200px'>
          <div className={animateCls}>
            <div className={cla(classes.desTitle)}>Swap <span style={{ color: '#90C8FF' }}>1000+</span> tokens across <span style={{ color: '#90C8FF' }}>7</span> chains</div>
            <img className={cla(classes.frameImg)} src={isMobile ? mframe6 : frame6} alt=''/>
            <img className={cla(classes.frameImg)} src={isMobile ? mframe7 : frame7} alt=''/>
            {
              <div className={cla(classes.symbolsContainer)}>
              {displayTokenSymbols[0].map(symbol => <img style={{ opacity: 0.5 }} className={classes.displayToken} src={symbol} alt=''/>)}</div>
            }
            {
            <div className={cla(classes.symbolsContainer)}>
              {displayTokenSymbols[1].map(symbol => <img style={{ opacity: 0.3 }} className={classes.displayToken} src={symbol} alt=''/>)}</div>
            }
            {
            <div className={cla(classes.symbolsContainer)}>
              {displayTokenSymbols[2].map(symbol => <img style={{ opacity: 0.1 }} className={classes.displayToken} src={symbol} alt=''/>)}</div>
            }
          </div>
        </ScrollLoad>
      </div>

      {/* <div className={classes.desWrapper}>
        <div>
          <ScrollLoad scrollHeigh='200px'>
            <div className={cla(classes.desTitle, animateCls)} style={{ marginTop: 0 }}>Supported by the best cross-chain bridges</div>
          </ScrollLoad>
          <ScrollLoad scrollHeigh='200px'>
            <img style={{ width: isMobile ? '80%' : 520 }} className={cla(classes.frameImg, animateCls)} src={frame1} alt=''/>
          </ScrollLoad>
          <ScrollLoad scrollHeigh='200px'>
            <img style={{ width: isMobile ? 120 : 228 }} className={cla(classes.frameImg, animateCls)} src={frame2} alt=''/>
          </ScrollLoad>
        </div>
        <div>
          <ScrollLoad scrollHeigh='200px'>
            <div className={cla(classes.desTitle, animateCls)}>Access all of the leading DEXes and their liquidity</div>
          </ScrollLoad>
          <ScrollLoad scrollHeigh='200px'>
            <img style={{ width: isMobile ? '80%' : 520 }} className={cla(classes.frameImg, animateCls)} src={frame3} alt=''/>
          </ScrollLoad>
          <ScrollLoad scrollHeigh='200px'>
            <img style={{ width: isMobile ? '80%' : 520 }} className={cla(classes.frameImg, animateCls)} src={frame4} alt=''/>
          </ScrollLoad>
          <ScrollLoad scrollHeigh='200px'>
            <img style={{ width: isMobile ? 47 : 88 }} className={cla(classes.frameImg, animateCls)} src={frame5} alt=''/>
          </ScrollLoad>
        </div>
        <ScrollLoad>
        <div>
            <ScrollLoad scrollHeigh='200px'>
              <div className={cla(classes.desTitle, animateCls)}>Swap <span style={{ color: '#90C8FF' }}>1000+</span> tokens across <span style={{ color: '#90C8FF' }}>7</span> chains</div>
            </ScrollLoad>
            <ScrollLoad scrollHeigh='200px'>
              <img style={{ width: isMobile ? '80%' : 520 }} className={cla(classes.frameImg, animateCls)} src={frame6} alt=''/>
            </ScrollLoad>
            <ScrollLoad scrollHeigh='200px'>
              <img style={{ width: isMobile ? 200 : 368 }} className={cla(classes.frameImg, animateCls)} src={frame7} alt=''/>
            </ScrollLoad>
              {
                <ScrollLoad scrollHeigh='200px'>
                  <div className={cla(classes.symbolsContainer, animateCls)}>
                  {displayTokenSymbols[0].map(symbol => <img style={{ opacity: 0.5 }} className={classes.displayToken} src={symbol} alt=''/>)}</div>
                </ScrollLoad>
              }
              {
              <ScrollLoad scrollHeigh='200px'>
               <div className={cla(classes.symbolsContainer, animateCls)}>
                  {displayTokenSymbols[1].map(symbol => <img style={{ opacity: 0.3 }} className={classes.displayToken} src={symbol} alt=''/>)}</div>
              </ScrollLoad> 
              }
              {
              <ScrollLoad scrollHeigh='200px'>
               <div className={cla(classes.symbolsContainer, animateCls)}>
                  {displayTokenSymbols[2].map(symbol => <img style={{ opacity: 0.1 }} className={classes.displayToken} src={symbol} alt=''/>)}</div>
              </ScrollLoad>
              }
          </div>
        </ScrollLoad>
      </div> */}
    </div>
  )
}