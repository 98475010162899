import { CSSProperties } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  card: {
    width: props => props.isMobile ? '100%' :340,
    marginTop: 48,
  },
  title: {
    fontSize: props => props.isMobile ? '0.2rem' : 36,
    fontWeight: 700,
    marginTop: 10,
  },
  des: {
    fontSize: props => props.isMobile ? '0.14rem' : 16,
    fontWeight: 500,
    marginTop: 12,
    lineHeight: '20px',
    color: '#444',
  }
}))

export type IProps = {
  isMobile?: boolean;
  width?: number;
  imgWidth?: number;
  title?: string;
  img?: string;
  des?: string | JSX.Element;
  styles?: CSSProperties;
};
export default function UseCase(props: IProps): JSX.Element {
  const { isMobile = false } = props;
  const classes = useStyles({isMobile});
  return (
    <div className={classes.card} style={{ width: props.width, ...props.styles }}>
      <img style={{ width: props.imgWidth }} src={props.img} alt='' />
      <h3 className={classes.title}>{props.title}</h3>
      <div className={classes.des}>{props.des}</div>
    </div>
  )
}