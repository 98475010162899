 import React, { useEffect, useRef, useState, CSSProperties } from 'react';
import lottie, { RendererType } from 'lottie-web';

type animationProps = {
  renderer?: RendererType | undefined,
  loop: boolean,
  path: string,
  canvasStyle: CSSProperties,
}

const Animation = (props: animationProps) => {
    const { renderer, loop, path, canvasStyle }  = props;
 
    const lottieRef = useRef<any>();
    const [, setLottieData] = useState<any>();
    useEffect(() => {
      setLottieData(
        lottie.loadAnimation({
            container: lottieRef.current,
            renderer,
            loop,
            path,
        })
      );
    }, [loop, path, renderer]);
    return <div ref={lottieRef} style={canvasStyle}></div>;
};
export default Animation;