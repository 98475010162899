import { useState, createRef, useEffect } from "react";

const ScrollLoad = (props: any) => {
  const [loading, setLoading] = useState(true);
  const ref: any = createRef();

  useEffect(() => {
    if (!ref.current) {
      return () => { }
    }

    const node: any = ref.current;
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setLoading(false);
          observer.unobserve(entry.target);
        }
      });
    });

    if (node != null) {
      observer.observe(node);
    }

    return () => {
      observer.disconnect();
    }
  }, [ref])

  return (
    loading ? <div style={{ minHeight: props.scrollHeight || '100vh' }} ref={ref}/> : <>{props.children}</>
  )
}

export default ScrollLoad;