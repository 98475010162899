import { createUseStyles } from "react-jss";
import ScrollLoad from "../components/ScrollLoad";
import 'animate.css';
import cla from "classnames";

import useCaseImg1 from '../assets/imgs/useCase1.png';
import useCaseImg2 from '../assets/imgs/useCase2.png';
import useCaseImg3 from '../assets/imgs/useCase3.png';
import useCaseImg4 from '../assets/imgs/useCase4.png';
import useCaseImg5 from '../assets/imgs/useCase5.png';
import UseCase from "../components/useCase";

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  section: {
    width: props => props.isMobile ? '84%' : 1300,
    margin: '0 auto',
    marginTop: props => props.isMobile ? 157 : 300,
  },
  header: {
    width: '100%',
    fontSize: props => props.isMobile ? '0.48rem' : 72,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 0,
  },
  useCaseWrap: {
    display: 'flex',
    justifyContent: 'space-around',
  }
}))

export type IProps = {
  isMobile: boolean;
};

export default function ThirdSection(props: IProps): JSX.Element {
  const { isMobile } = props;
  const classes = useStyles({isMobile});
  const animateCls = 'animate__animated animate__fadeInUp animate__slow';
  const animateClsFast = 'animate__animated animate__fadeInUp';
  // const animateHeaderCls = 'animate__animated animate__fadeIn animate__slower';
  return (
    <div className={cla(classes.section)}>
      <ScrollLoad>
        <div className={animateCls}>
          <h2 className={classes.header} style={{ margin: 0 }}>A variety of</h2>
          <h2 className={classes.header} style={{ margin: 0 }}>use cases</h2>
        </div>
      <ScrollLoad>
      {!isMobile &&
        <div className={cla(classes.useCaseWrap, animateClsFast)}>
          <UseCase img={useCaseImg1} imgWidth={96} title='For wallets' des={<><div>Build a white-label cross-chain DEX</div><div>aggregator natively in your wallet</div></>} />
          <UseCase img={useCaseImg2} imgWidth={75} title='For DEXes' des={<><div>Connect your standalone DEX deployments</div><div>on multiple chains with unified liquidity</div></>} />
          <UseCase img={useCaseImg3} imgWidth={100} title='For NFT  & SocialFi' des={<><div>Onramp users holding any token from any</div><div>chain for purchases and subscriptions</div></>} />
        </div>}
      </ScrollLoad>
      <ScrollLoad>
      {!isMobile &&
        <div className={cla(classes.useCaseWrap, animateClsFast)}>
          <UseCase styles={{ marginLeft: 200}} img={useCaseImg4} imgWidth={100} title='For DeFi protocols' des={<><div>Integrate native cross-chain strategies that</div><div>offer the best end-to-end rates</div></>} />
          <UseCase styles={{ marginRight: 200}} img={useCaseImg5} imgWidth={100} title='For dApps & Web 3' des='Offer a simplified one-click Web 3 payment option for users from any chain' />
        </div>}
      </ScrollLoad>
      </ScrollLoad>
      {isMobile && <ScrollLoad scrollHeight='200px'>
        <div className={cla(classes.mUseCaseWrap, animateCls)}>
          <UseCase isMobile={isMobile} img={useCaseImg1} imgWidth={96} title='For wallets' des={<><div>Build a white-label cross-chain DEX</div><div>aggregator natively in your wallet</div></>} />
        </div>
      </ScrollLoad>}
      {isMobile && <ScrollLoad scrollHeight='200px'>
        <div className={cla(classes.mUseCaseWrap, animateCls)}>
          <UseCase isMobile={isMobile} img={useCaseImg2} imgWidth={75} title='For DEXes' des={<><div>Connect your standalone DEX deployments</div><div>on multiple chains with unified liquidity</div></>} />
        </div>
      </ScrollLoad>}
      {isMobile && <ScrollLoad scrollHeight='200px'>
        <div className={cla(classes.mUseCaseWrap, animateCls)}>
          <UseCase isMobile={isMobile} img={useCaseImg3} imgWidth={100} title='For NFT  & SocialFi' des={<><div>Onramp users holding any token from any</div><div>chain for purchases and subscriptions</div></>} />
        </div>
      </ScrollLoad>}
      {isMobile && <ScrollLoad scrollHeight='200px'>
        <div className={cla(classes.mUseCaseWrap, animateCls)}>
          <UseCase isMobile={isMobile} img={useCaseImg4} imgWidth={100} title='For DeFi protocols' des={<><div>Integrate native cross-chain strategies that</div><div>offer the best end-to-end rates</div></>} />
        </div>
      </ScrollLoad>}
      {isMobile && <ScrollLoad scrollHeight='200px'>
        <div className={cla(classes.mUseCaseWrap, animateCls)}>
          <UseCase isMobile={isMobile} styles={{ marginRight: 200}} img={useCaseImg5} imgWidth={100} title='For dApps & Web 3' des={<><div>Offer a simplified one-click Web 3 payment</div><div>option for users from any chain</div></>} />
        </div>
      </ScrollLoad>}
    </div>
  )
}