import { createUseStyles } from "react-jss";
import cla from "classnames";
import 'animate.css';
import ScrollLoad from "../components/ScrollLoad";

import blockBg1 from '../assets/imgs/blockBg1.png';
import groupImg1 from '../assets/imgs/groupImg1.png';
import blockBg2 from '../assets/imgs/blockBg2.png';
import groupImg2 from '../assets/imgs/groupImg2.png';
import blockBg3 from '../assets/imgs/blockBg3.png';
import groupImg3 from '../assets/imgs/groupImg3.png';
import mBlockBg1 from '../assets/imgs/mBlockBg1.png';
import mBlockBg2 from '../assets/imgs/mBlockBg2.png';
import mGroupImg2 from '../assets/imgs/mGroupImg2.png';
import mBlockBg3 from '../assets/imgs/mBlockBg3.png';

const useStyles = createUseStyles<string, { isMobile: boolean }>(() => ({
  section: {
    width: props => props.isMobile ? '84%' : 1300,
    marginTop: props => props.isMobile ? 150 : 240,
    margin: '0 auto',
  },
  header: {
    width: props => props.isMobile ? '2.9rem' : '100%',
    fontSize: props => props.isMobile ? '0.48rem' : 72,
    fontWeight: 700,
    textAlign: 'left',
  },
  block: {
    position: 'relative',
    marginTop: 48,
    backgroundImage: `url(${blockBg1})`,
    backgroundSize: 'cover',
    height: 360,
    paddingLeft: 40,
    paddingRight: 40,

  },
  mblock: {
    marginTop: 48,
    backgroundImage: `url(${mBlockBg1})`,
    backgroundSize: 'cover',
    height: 530,
    paddingLeft: 20,
  },
  blockHeader: {
    width: 529,
    textAlign: 'left',
    paddingTop: 81,
    fontSize: 36,
    fontWeight: 700,
  },
  mBlockHeader: {
    width: '2.62rem',
    textAlign: 'left',
    paddingTop: 40,
    fontSize: '0.2rem',
    fontWeight: 700,
  },
  blockDes: {
    lineHeight: '26px',
    width: props => props.isMobile ? '100%' : 380,
    textAlign: 'left',
    marginTop: 16,
    fontSize: props => props.isMobile ? '0.16rem' : 16,
    fontWeight: 400,
    color: '#444',
    wordBreak: 'break-word'
  },
  groupImg: {
    width: 500,
    height: 360,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  mGroupImg: {
    width: '100%',
    marginTop: 25,
    marginLeft: -20,
  },
  blockWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  block2: {
    width: '49%',
    marginRight: '1%',
    height: 680,
    position: 'relative',
    backgroundImage: `url(${blockBg2})`,
    padding: '60px 40px 40px 40px',
    backgroundSize: 'cover',
  },
  block3: {
    width: '49%',
    height: 680,
    position: 'relative',
    backgroundImage: `url(${blockBg3})`,
    padding: '60px 40px 40px 40px',
    backgroundSize: 'cover',
  },
  groupImg2: {
    position: 'absolute',
    left: 'calc(50% - 255px)',
    height: 340,
    width: 510,
    bottom: 30,
  },
  groupImg3: {
    position: 'absolute',
    height: 380,
    width: 540,
    left: 'calc(50% - 270px)',
    bottom: 30,
  },
  blockHeader2: {
    fontSize: 36,
    fontWeight: 700,
    textAlign: 'left'
  },
  blockDes2: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 16,
    textAlign: 'left',
    width: 400,
    color: '#444',
    lineHeight: '26px',
  }
}));

export type IProps = {
  isMobile: boolean;
};

export default function SecondSection(props: IProps): JSX.Element {
  const { isMobile } = props;
  const classes = useStyles({isMobile});
  const animateCls = 'animate__animated animate__fadeInUp animate__slow';
  const animateFadeInCls = 'animate__animated animate__fadeIn animate__slower';

  return (
    <div className={cla(classes.section)}>
      <ScrollLoad>
        <div className={cla(classes.header, animateFadeInCls)}>
          {isMobile ? <>
            <div>The best</div>
            <div>cross-chain</div>
            <div>swap rates</div>
            <div>with just one</div>
            <div>click </div>
          </> : <><h2 className={classes.header} style={{ margin: 0 }}>The best cross-chain swap</h2> <h2 className={classes.header} style={{ margin: 0 }}>rates with just one click </h2></>}
        </div>
      </ScrollLoad>
      {!isMobile ?
        <div className={cla(classes.block, animateCls)}>
          <img className={cla(classes.groupImg, animateFadeInCls)} src={groupImg1} alt='' />
          <ScrollLoad>
            <div className={cla(classes.blockHeader, animateFadeInCls)}>
              <h3 style={{ margin: 0 }}>A seamless single-click</h3> 
              <h3 style={{ margin: 0 }}>cross-chain swap UX</h3>
            </div>
            <div className={cla(classes.blockDes, animateFadeInCls)}>
              With just one transaction approval, ChainHop executes and completes multiple underlying transactions routed through all of the leading DEXes and bridges via inter-chain messaging.
            </div>
          </ScrollLoad>
        </div> :
        <div className={cla(classes.mblock, animateCls)}>
          <div className={classes.mblockDes}>
            <ScrollLoad>
              <h3 className={cla(classes.mBlockHeader, animateFadeInCls)}>
                A seamless single-click cross-chain swap UX
              </h3>
              <div className={cla(classes.blockDes, animateFadeInCls)}>
                With just one transaction approval, ChainHop executes and completes multiple underlying transactions routed through all of the leading DEXes and bridges via inter-chain messaging.
              </div>
            </ScrollLoad>
          </div>
          <ScrollLoad>
            <img className={cla(classes.mGroupImg, animateFadeInCls)} src={groupImg1} alt='' />
          </ScrollLoad>
        </div>}
      {!isMobile && <div className={cla(classes.blockWrapper)}>
        <div className={cla(classes.block2, animateCls)}>
          <img className={cla(classes.groupImg2, animateCls)} src={groupImg2} alt='' />
          <ScrollLoad>
            <h3 className={cla(classes.blockHeader2, animateCls)}>
              The best-in-class cross-chain routing algorithm
            </h3>
            <div className={cla(classes.blockDes2, animateCls)}>
              Offering the best end-to-end rates or even arbitrage opportunities by leveraging multiple intermediary tokens and chains while factoring in the gas costs.          
            </div>
          </ScrollLoad>
        </div>
        <div className={cla(classes.block3, animateCls)}>
          <img className={cla(classes.groupImg3, animateCls)} src={groupImg3} alt='' />
          <ScrollLoad>
            <h3 className={cla(classes.blockHeader2, animateCls)}>
              Unprecedented omni-chain liquidity composition
            </h3>
            <div className={cla(classes.blockDes2, animateCls)}>
              ChainHop is the first protocol that seamlessly composes the liquidity of an arbitrary bridge and the liquidity of an arbitrary DEX—enabling developers and users to access all of the popular liquidity sources across multiple different chains.
            </div>
          </ScrollLoad>
        </div>
        </div>}
      {isMobile && <div className={cla(classes.mblock, animateCls)} style={{ backgroundImage: `url(${mBlockBg2})`}}>
        <div className={classes.mblockDes}>
            <ScrollLoad>
              <h3 className={cla(classes.mBlockHeader, animateCls)}>
                The best-in-class cross-chain routing algorithm
              </h3>
              <div className={cla(classes.blockDes, animateCls)}>
                Offering the best end-to-end rates or even arbitrage opportunities by leveraging multiple intermediary tokens and chains while factoring in the gas costs.
              </div>
            </ScrollLoad>
          </div>
          <ScrollLoad>
            <img className={cla(classes.mGroupImg, animateCls, 'animate__delay-0.5s')} src={mGroupImg2} alt='' />
          </ScrollLoad>
        </div>
      }
      {isMobile && <div className={cla(classes.mblock, animateCls)} style={{ backgroundImage: `url(${mBlockBg3})`}}>
        <div className={classes.mblockDes}>
            <ScrollLoad>
              <h3 className={cla(classes.mBlockHeader, animateCls)}>
                Unprecedented omni-chain liquidity composition
              </h3>
              <div className={cla(classes.blockDes, animateCls)}>
                ChainHop is the first protocol that seamlessly composes the liquidity of an arbitrary bridge and the liquidity of an arbitrary DEX—enabling developers and users to access all of the popular liquidity sources across multiple different chains.
              </div>
            </ScrollLoad>
          </div>
          <ScrollLoad>
            <img className={cla(classes.mGroupImg, animateCls, 'animate__delay-0.5s')} src={groupImg3} alt='' />
          </ScrollLoad>
        </div>
      }
    </div>
  )
}